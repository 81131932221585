import { createSlice } from '@reduxjs/toolkit';
import { IProject, IProjectUsers, INonMandatoryDetails } from 'app/models';
import { ProjUsers } from '../../types';
import {
  fetchProjectsList,
  fetchNonManditoryDetails,
  fetchProjUsersList,
  fetchProjectById,
} from '../actions/projects.actions';

type InitialState = {
  projects: IProject[];
  loading: boolean;
  nonManditoryDetails: INonMandatoryDetails;
  projUsers: ProjUsers;
  singleProject: any;
};

const initialState: InitialState = {
  projects: [],
  loading: false,
  nonManditoryDetails: {} as INonMandatoryDetails,
  projUsers: {},
  singleProject: [],
};

const projectsSlice = createSlice({
  name: 'projectsSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjectsList.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
      })
      .addCase(fetchNonManditoryDetails.fulfilled, (state, action) => {
        state.nonManditoryDetails = action.payload;
      })
      .addCase(fetchProjUsersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjUsersList.fulfilled, (state, action) => {
        state.loading = false;
        state.projUsers = action.payload;
      })
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        state.singleProject = action.payload;
      });
  },
});

export default projectsSlice.reducer;
