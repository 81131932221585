import componentConfig from 'app/main/component/component.config';
import { PermissionEnum as pe } from 'app/models/common/enums';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Application = lazy(() => import('./Application'));

const BuildPipelines = lazy(() => import('./pages/BuildPipelines'));
const Components = lazy(() => import('./pages/Components'));
const Environment = lazy(() => import('./pages/Environment'));
const FileChanges = lazy(() => import('./pages/FileChanges'));
const NewComponent = lazy(() => import('./pages/NewComponent'));
const ReleasePipelines = lazy(() => import('./pages/ReleasePipelines'));
const Settings = lazy(() => import('./pages/Settings'));
const Deployments = lazy(() => import('./pages/Deployments'));
const VersionControl = lazy(() => import('./pages/VersionControl'));
const ApplicationUsers = lazy(() => import('./pages/ApplicationUsers'));
const ContainerRegistry = lazy(() => import('./pages/ContainerRegistry'));

const applicationRoutes: RouteObject[] = [
  {
    path: '/app/:appId',
    element: <Application />,
    children: [
      {
        path: '',
        element: <Components />,
        handle: { permissions: [pe.VIEW_ALL_APPS, pe.VIEW_APP] },
      },
      {
        path: 'component/create',
        element: <NewComponent />,
      },
      {
        path: 'users',
        element: <ApplicationUsers />,
      },
      {
        path: 'changes',
        element: <FileChanges />,
      },
      {
        path: 'build-pipelines',
        element: <BuildPipelines />,
        handle: { permissions: [] },
      },
      {
        path: 'release-pipelines',
        element: <ReleasePipelines />,
        handle: { permissions: [] },
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            path: '',
            element: <></>,
            handle: { permissions: [] },
          },
          {
            path: 'version-control',
            element: <VersionControl />,
            handle: { permissions: [] },
          },
          {
            path: 'environments',
            element: <Environment />,
            handle: { permissions: [] },
          },
          {
            path: 'deployments',
            element: <Deployments />,
            handle: { permissions: [] },
          },
          {
            path: 'authorize-container-registry',
            element: <ContainerRegistry />,
          },
        ],
      },
      ...componentConfig,
    ],
  },
];

export default applicationRoutes;
