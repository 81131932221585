import { MsalConfig, B2CPolicies, ConfigOptions } from 'app/models';
import { LogLevel } from '@azure/msal-browser';
export class MsalConfigService {
  constructor(private readonly configuration: ConfigOptions) {}

  get msalConfig(): MsalConfig {
    const b2cPolicies: B2CPolicies = {
      names: {
        signIn: this.configuration.REACT_APP_POLICY_NAME,
      },
      authorities: {
        signIn: {
          authority: this.configuration.REACT_APP_AUTHORITY,
        },
      },
      authorityDomain: this.configuration.REACT_APP_AUTHORITY_DOMAIN,
    };
    return {
      auth: {
        clientId: this.configuration.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: this.configuration.REACT_APP_REDIRECT_URI_LOGIN, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: this.configuration.REACT_APP_REDIRECT_URI_LOGOUT, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
      },
      cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            if (process.env.NODE_ENV !== 'development') return;
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
        },
      },
    };
  }
}
