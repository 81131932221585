import { IPublicClientApplication } from '@azure/msal-browser';
import { RequestHandler } from 'libs/request-handler';
import { IRecentApplications, Services } from 'app/models';

const apiServiceName: Services = 'app-designer';
class RecentApplicationsService extends RequestHandler {
  endpath = 'recent-applications';

  getRecentApplications(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<IRecentApplications[]>(
      `/${this.endpath}/get-recent-applications/`
    );
  }

  createOrUpdate(instance: IPublicClientApplication, appId: string) {
    return this.requestHandler(instance).post<IRecentApplications>(
      `/${this.endpath}/create-or-update/${appId}`
    );
  }
}
export default new RecentApplicationsService(apiServiceName);
