import { IPublicClientApplication } from '@azure/msal-browser';
import { RequestHandler } from 'libs/request-handler';
// import axios, { AxiosResponse } from 'axios';
import { Services } from 'app/models';

const apiServiceName: Services = 'apifactory-service';

class ApiService extends RequestHandler {
  endpath = '/api/v1';
  // http://localhost:8081/api/v1/validProjectRepos/2000084609@hexaware.com

  generateSpec(instance: IPublicClientApplication, formObj: FormData) {
    const headers = { 'content-type': 'multipart/form-data' };
    return this.requestHandler(instance).post(`${this.endpath}/generatespec`, formObj, {
      headers: headers,
    });
  }

  getValidProjectRepos(instance: IPublicClientApplication, userEmail: string) {
    return this.requestHandler(instance).get(`${this.endpath}/validProjectRepos/${userEmail}`);
  }

  generateServerClientCodes(instance: IPublicClientApplication, formObj: any) {
    console.log('generateServerClientCode', formObj);
    const headers = { 'content-type': 'multipart/form-data' };
    return this.requestHandler(instance).post(`${this.endpath}/generateclient`, formObj, {
      headers: headers,
    });
  }

  generateDocumentation(instance: IPublicClientApplication, formObj: any) {
    const headers = { 'content-type': 'multipart/form-data' };
    return this.requestHandler(instance).post(`${this.endpath}/generatedoc`, formObj, {
      headers: headers,
    });
  }

  downloadspec(instance: IPublicClientApplication, formObj: any) {
    // console.log('ConvertToOAS', formObj);
    const headers = { 'content-type': 'multipart/form-data' };
    return this.requestHandler(instance).post(`${this.endpath}/generatespec`, formObj, {
      responseType: 'blob',
      headers: headers,
    });
  }

  getListofRepos(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`${this.endpath}/gitUtil/getRepoDetails`);
  }

  getListofReposifProject(instance: IPublicClientApplication, projectId: string) {
    return this.requestHandler(instance).get(`${this.endpath}/git/${projectId}/repos`);
  }

  getFilesFromRepository(instance: IPublicClientApplication, repoName: string) {
    return this.requestHandler(instance).get(
      `${this.endpath}/gitUtil/getFilesFromRepository/${repoName}`
    );
  }

  getFilesFromRepositoryifProject(
    instance: IPublicClientApplication,
    projectId: string,
    repoName: string
  ) {
    return this.requestHandler(instance).get(
      `${this.endpath}/git/${projectId}/filesFromRepo/${repoName}`
    );
  }

  generateTemplate(instance: IPublicClientApplication, formData: any) {
    const headers = { 'content-type': 'multipart/form-data' };
    return this.requestHandler(instance).post(`${this.endpath}/generateTemplate`, formData, {
      headers: headers,
    });
  }

  uploadYmlFile(instance: IPublicClientApplication, formObj: any) {
    // console.log('ConvertYMLtoJSON', formObj);
    const headers = { 'content-type': 'multipart/form-data' };
    return this.requestHandler(instance).post(`${this.endpath}/convertYamltoJson`, formObj, {
      headers: headers,
    });
  }

  getEndpoints(instance: IPublicClientApplication, data: any) {
    // console.log('getEndpoints', data);
    return this.requestHandler(instance).post(
      `https://apifactoryaiservice.azurewebsites.net/executeService`,
      data,
      {
        responseType: 'json',
      }
    );
  }

  generateSpecification(instance: IPublicClientApplication, data: any) {
    // console.log('getSpec', data);
    return this.requestHandler(instance).post(
      `https://apifactoryaiservice.azurewebsites.net/executeService`,
      data,
      {
        responseType: 'json',
      }
    );
  }

  saveToGithub(instance: IPublicClientApplication, data: any) {
    // console.log('saveToRepo', data);
    return this.requestHandler(instance).post(
      `${this.endpath}/PushChatAPiGeneratedCodeToGit`,
      data,
      {
        responseType: 'text',
      }
    );
  }

  getAccountDetails(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`${this.endpath}/getAccountDetails`);
  }

  saveAccountDetails(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/saveAccountDetails`, data, {
      responseType: 'text',
    });
  }

  updateAccountDetails(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/updateAccountDetails`, data, {
      responseType: 'text',
    });
  }

  deleteAccountDetails(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/deleteAccountDetails`, data, {
      responseType: 'text',
    });
  }

  saveProjectDetails(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/saveProjectDetails`, data, {
      responseType: 'text',
    });
  }

  updateProjectDetails(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/updateProjectDetails`, data, {
      responseType: 'text',
    });
  }

  deleteProjectDetails(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/deleteProjectDetails`, data, {
      responseType: 'text',
    });
  }

  saveUserProject(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/saveUserProject`, data, {
      responseType: 'text',
    });
  }

  deleteUserProject(instance: IPublicClientApplication, data: any) {
    return this.requestHandler(instance).post(`${this.endpath}/deleteUserProject`, data, {
      responseType: 'text',
    });
  }

  getUsersForProject(instance: IPublicClientApplication, projectId: string) {
    return this.requestHandler(instance).get(`${this.endpath}/getUsersForProject/${projectId}`);
  }

  getAccountName(instance: IPublicClientApplication) {
    console.log('accname');
    return this.requestHandler(instance).get(`${this.endpath}/getAccountName`);
  }

  //////

  getDataModelbyProjectId(instance: IPublicClientApplication, projectId: any) {
    console.log('getDataModelbyprojectid', projectId);
    return this.requestHandler(instance).get(
      `${this.endpath}/getDataModelByProjectId/${projectId}`
    );
  }

  deleteDataModelById(instance: IPublicClientApplication, dataModelId: any) {
    console.log('deleteDatModelByDataModelId', dataModelId);
    return this.requestHandler(instance).post(
      `${this.endpath}/deleteDataModelById/${dataModelId}`,
      ``
    );
  }

  getDataModel(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`${this.endpath}/getDataModel`);
  }

  getProjectDetails(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`${this.endpath}/getProjectDetails`);
  }

  getDataModelById(instance: IPublicClientApplication, dataModelId: any) {
    console.log('data Model Id : ' + dataModelId);
    return this.requestHandler(instance).get(`${this.endpath}/getDataModelById/${dataModelId}`);
  }

  saveDataModel(instance: IPublicClientApplication, dataModelJSONString: string) {
    console.log('saveDataModel : ');
    return this.requestHandler(instance).post(
      `${this.endpath}/saveDataModel`,
      dataModelJSONString,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  getAPIsByProjectId(instance: IPublicClientApplication, projectId: any) {
    console.log('getAPIsByProjectId : ');
    return this.requestHandler(instance).get(
      `${this.endpath}/getAPIDetailsByProjectId/${projectId}`
    );
  }

  saveAPIDetails(instance: IPublicClientApplication, apiMetaInfo: string) {
    console.log('Save API Details method called!');
    return this.requestHandler(instance).post(`${this.endpath}/saveAPIDetails`, apiMetaInfo, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  deleteApiDetailsByApiId(instance: IPublicClientApplication, data: any) {
    console.log('deleteApiDetailsByApiId : ', data.ApiID);
    return this.requestHandler(instance).post(
      `${this.endpath}/deleteApiDetailsByApiId/${data.ApiID}`,
      data
    );
  }

  getAPIDetailsByAPIId(instance: IPublicClientApplication, apiId: any) {
    console.log('Inside getAPIDetailsByAPIId : ' + apiId);
    return this.requestHandler(instance).get(`${this.endpath}/getAPIDetailsByAPIId/${apiId}`);
  }

  getProjectNamesforUsers(instance: IPublicClientApplication, email: any) {
    console.log('Inside getProjectNamesforUser : ' + email);
    return this.requestHandler(instance).get(`${this.endpath}/getProjectNamesforUser/${email}`);
  }

  pushAPISpecificationtorepo(
    instance: IPublicClientApplication,
    apiSpecification: any,
    apiName: string,
    projectId: any
  ) {
    const requestBody = {
      apiSpecification,
      apiName,
      projectId,
    };
    return this.requestHandler(instance).post(`${this.endpath}/pushAPISpecToRepo`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  convertOAS2ToOAS3(instance: IPublicClientApplication, formObj: FormData) {
    const headers = { 'content-type': 'multipart/form-data' };
    return this.requestHandler(instance).post(`${this.endpath}/convertoas2tooas3`, formObj, {
      headers: headers,
    });
  }
}

export default new ApiService(apiServiceName);
