import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

interface IProps {
  tooltip: string;
}

export default function InfoTooltip({ tooltip }: IProps) {
  return (
    <Tooltip title={tooltip} className="gap-10">
      <InfoOutlinedIcon />
    </Tooltip>
  );
}
