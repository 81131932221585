import { combineReducers } from '@reduxjs/toolkit';
import applicationReducer from './slices/application.slice';
import settingsReducer from './slices/settings.slice';
import mraGalleryReducer from './slices/mra-gallery.slice';
import azureDevopsReducer from './slices/azure-devops.slice';

const reducer = combineReducers({
  application: applicationReducer,
  settings: settingsReducer,
  mraGallery: mraGalleryReducer,
  azureDevops: azureDevopsReducer,
});

export default reducer;
