import { createAsyncThunk } from '@reduxjs/toolkit';
import { IEntity } from 'app/models';
import EntityService from 'app/services/entity-designer/entity.service';

export const fetchComponentEntities = createAsyncThunk(
  'entitySlice/getComponentEntities',
  async (data: WithInstance<{ compId?: string; appId?: string }>) => {
    const response = await EntityService.getComponentEntities(
      data.instance,
      data.compId,
      data.appId
    );
    return response.data;
  }
);

export const addEntity = createAsyncThunk(
  'entitySlice/addEntities',
  async (data: WithInstance<{ details: any }>) => {
    const createEntities = [];
    createEntities.push(data.details);
    const obj = {
      appId: data.details.appId,
      compId: data.details.compId,
      entity: createEntities,
    };
    await EntityService.addEntity(data.instance, obj);
  }
);

export const updateEntity = createAsyncThunk(
  'entitySlice/upadateEntities',
  async (data: WithInstance<{ details: IEntity }>) => {
    await EntityService.updateEntity(data.instance, data.details);
  }
);

export const addEntitywithNestedRelations = createAsyncThunk(
  'entitySlice/addEntitywithNestedRelations',
  async (
    data: WithInstance<{ entityData: any; compId?: string; entityType?: string; appId?: string }>
  ) => {
    await EntityService.addEntitywithNestedRelations(
      data.instance,
      data.entityData,
      data.entityType,
      data.compId,
      data.appId
    );
    //EntityService.fetchComponentEntities({ instance, appId: details.appId, compId : details.compId }));
  }
);
