import { createSlice } from '@reduxjs/toolkit';
import { Tags, ICurrentTags, IApiParameters } from 'app/models/app-designer/apibuilder.model';
import { getComponentDesignSpecByCompId } from '../actions/apibuilder.actions';
import {
  ICurrentApiMethod,
  ICurrentServiceMethod,
  ICurrentRepoMethod,
} from '../../apiBuilder/type/apibuilder.type';

type InitialMraState = {
  builderList: Tags[];
  apis: any[];
  services: Tags[];
  repos: Tags[];
  layer: string;
  tags: any[];
  models: any[];
  activeTagIndex: number;
  designSpecModels: any[];
  currentTags: ICurrentTags;
  currentApiMethod: ICurrentApiMethod;
  currentServiceMethod: ICurrentServiceMethod;
  currentRepoMethod: ICurrentRepoMethod;
  tabValue: string;
};

const initialState: InitialMraState = {
  builderList: [],
  apis: [],
  services: [],
  repos: [],
  layer: 'controller',
  tags: [],
  models: [],
  activeTagIndex: -1,
  designSpecModels: [],
  currentTags: {} as ICurrentTags,
  currentApiMethod: {} as ICurrentApiMethod,
  currentServiceMethod: {} as ICurrentServiceMethod,
  currentRepoMethod: {} as ICurrentRepoMethod,
  tabValue: 'api',
};

export function getTarget(layer: any) {
  const layerMap: any = {
    controller: 'apis',
    service: 'services',
    repo: 'repos',
  };
  return layerMap[layer];
}
const apiBuilderSlice = createSlice({
  name: 'apiBuilderSlice',
  initialState,
  reducers: {
    initializeAPIBuilder: (state, action) => {
      const { layer, models, tags } = action.payload;
      state.layer = layer;
      state.models = models;
      state.tags = tags;
      state.activeTagIndex = -1;
    },
    setCurrentTags: (state, action) => {
      state.currentTags = action.payload;
    },
    updateCurrentTags: (state, action) => {
      const fieldindex: number = state.currentTags.methods.findIndex(
        (tag: any) => tag.methodName === action.payload.currentApiMethod.methodName
      );
      const updatedTag: any = {
        methodName: action.payload.methodName,
        parameters: action.payload.parameters,
        description: action.payload.description,
        methodsReturnType: action.payload.methodsReturnType,
        httpMethod: action.payload.httpMethod,
        httpRoute: action.payload.httpRoute,
        requestBody: action.payload.requestBody,
      };

      state.currentTags.methods[fieldindex] = updatedTag;

      const fieldindexTag: number = state.tags.findIndex(
        (tag: any) => tag.tagName === state.currentTags.tagName
      );
      state.apis[fieldindexTag] = state.currentTags;
      state.tags[fieldindexTag] = state.currentTags;
    },
    setCurrentApiMethod: (state, action) => {
      state.currentApiMethod = action.payload;
    },
    setCurrentServiceMethod: (state, action) => {
      state.currentServiceMethod = action.payload;
    },
    setCurrentRepoMethod: (state, action) => {
      state.currentRepoMethod = action.payload;
    },
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    addNewOperation: (state, action) => {
      state.currentTags?.methods.push(action.payload);
    },
    addNewTag: (state, action) => {
      state.tags.push(action.payload);
      if (state.tabValue === 'api') {
        state.apis.push(action.payload);
      } else if (state.tabValue === 'business') {
        state.services.push(action.payload);
      } else if (state.tabValue === 'repo') {
        state.repos.push(action.payload);
      }
    },
    updateTagMethod: (state, action) => {
      if (state.tabValue === 'api') {
        state.currentApiMethod = action.payload;
      } else if (state.tabValue === 'business') {
        state.currentServiceMethod = action.payload;
      } else if (state.tabValue === 'repo') {
        state.currentRepoMethod = action.payload;
      }
    },
    setBuilderList: (state, action) => {
      state.builderList = action.payload;
      state.apis = action.payload.filter((tag: ICurrentTags) => tag.type === 'controller');
      state.services = action.payload.filter((tag: ICurrentTags) => tag.type === 'service');
      state.repos = action.payload.filter((tag: ICurrentTags) => tag.type === 'repo');
    },
    setTags: (state: any, action) => {
      const target: any = getTarget(state.layer);
      state[target] = action.payload;
    },
    setDesignSpecModels: (state, action) => {
      state.designSpecModels = action.payload;
    },
    setLayer: (state, action) => {
      state.layer = action.payload;
    },
    setModal: (state, action) => {
      state.models = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getComponentDesignSpecByCompId.fulfilled, (state, action) => {
      state.builderList = action.payload;
      state.apis = action.payload.filter((tag: any) => tag.type === 'controller');
      state.services = action.payload.filter((tag: any) => tag.type === 'service');
      state.repos = action.payload.filter((tag: any) => tag.type === 'repo');
    });
  },
});

export const {
  setCurrentTags,
  setCurrentApiMethod,
  setTabValue,
  setCurrentServiceMethod,
  setCurrentRepoMethod,
  initializeAPIBuilder,
  addNewOperation,
  addNewTag,
  setBuilderList,
  updateTagMethod,
  setDesignSpecModels,
  setTags,
  updateCurrentTags,
  setLayer,
  setModal,
} = apiBuilderSlice.actions;

export default apiBuilderSlice.reducer;
