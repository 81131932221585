export class ArrayDataField {
  arrayId: string;
  dataFieldName: string;
  ArrayItems: string;
  MinimumItems: string;
  MaximumItems: string;
  dataFieldId: number;
  constructor(
    arrayId: string,
    dataFieldName: string,
    ArrayItems: string,
    MinimumItems: string,
    MaximumItems: string,
    dataFieldId: number
  ) {
    this.arrayId = arrayId;
    this.dataFieldName = dataFieldName;
    this.ArrayItems = ArrayItems;
    this.MaximumItems = MaximumItems;
    this.MinimumItems = MinimumItems;
    this.dataFieldId = dataFieldId;
  }
}
