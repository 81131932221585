import { FieldModel } from '../apiDataModels/FieldModel.model';

export class RequestParameter {
  parameterId: number;
  parameterName: string;
  parameterType: string;
  parameterDescription: string;
  parameterDataType: string;
  parameterRequired: boolean;
  parametreDefaultValue: string;
  parameterRequestBody: FieldModel;
  constructor(
    parameterId: number,
    parameterName: string,
    parameterType: string,
    parameterDescription: string,
    parameterDataType: string,
    parameterRequired: boolean,
    parametreDefaultValue: string,
    parameterRequestBody: FieldModel
  ) {
    this.parameterId = parameterId;
    this.parameterName = parameterName;
    this.parameterType = parameterType;
    this.parameterDescription = parameterDescription;
    this.parameterDataType = parameterDataType;
    this.parameterRequired = parameterRequired;
    this.parametreDefaultValue = parametreDefaultValue;
    this.parameterRequestBody = parameterRequestBody;
  }
}
