import { createAsyncThunk } from '@reduxjs/toolkit';
import componentdesignspecService from 'app/services/app-designer/componentdesignspec.service';

export const getComponentDesignSpecByCompId = createAsyncThunk(
  'apiBuilder/getComponentDesignSpecByCompId',
  async (data: WithInstance<{ componentId?: string; appId?: string }>) => {
    const response: any = await componentdesignspecService.getComponentDesignSpecByComponentId(
      data.instance,
      data.componentId,
      data.appId
    );
    return response?.data;
  }
);

export const generateCodeFromDesignSpec = createAsyncThunk(
  'apiBuilder/generateCodeFromDesignSpec',
  async (
    data: WithInstance<{ componentId?: string; appId?: string; layerType: string; designSpec: any }>
  ) => {
    const response: any = await componentdesignspecService.generateCodeFromDesignSpec(
      data.instance,
      data.layerType,
      data.designSpec,
      data.componentId,
      data.appId
    );
    return response?.data;
  }
);

export const saveDesignSpecByType = createAsyncThunk(
  'apiBuilder/saveDesignSpecByType',
  async (
    data: WithInstance<{ componentId?: string; appId: string; layer: string; designSpec: any }>
  ) => {
    const res = await componentdesignspecService.saveDesignSpecByType(
      data.instance,
      data.layer,
      data.designSpec,
      data.componentId,
      data.appId
    );
    return res.data ?? [];
  }
);
