import loading from 'assets/loading.json';
import upload from 'assets/upload.json';

type LottieConfigType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const LottieConfig: LottieConfigType = {
  loading,
  upload,
};

export default LottieConfig;
