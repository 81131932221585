import { IPublicClientApplication } from '@azure/msal-browser';
import { Services } from 'app/models';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'mra-management';

class ImageService extends RequestHandler {
  endpath = 'image';

  getImageSrc(instance: IPublicClientApplication, queryParams: URLSearchParams) {
    return this.requestHandler(instance).get(
      `${this.endpath}/download-base64-image?${queryParams.toString()}`
    );
  }
}

export default new ImageService(apiServiceName);
