import { createSlice } from '@reduxjs/toolkit';
import { IApplication, IAudit } from 'app/models';
import { getRecentApplications, getAllAudits, getAuditsById } from '../actions/home.actions';

type InitialState = {
  recentApplications: IApplication[];
  loading: boolean;
  appsLoading: boolean;
  audits: IAudit[];
};

const initialState: InitialState = {
  recentApplications: [],
  loading: false,
  appsLoading: false,
  audits: [],
};

const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecentApplications.pending, (state) => {
        state.appsLoading = true;
      })
      .addCase(getRecentApplications.fulfilled, (state, action) => {
        state.recentApplications = action.payload;
        state.appsLoading = false;
      })
      .addCase(getAllAudits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAudits.fulfilled, (state, action) => {
        state.audits = action.payload;
        state.loading = false;
      })
      .addCase(getAuditsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuditsById.fulfilled, (state, action) => {
        state.audits = action.payload;
        state.loading = false;
      });
  },
});

export default homeSlice.reducer;
