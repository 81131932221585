import { RequestParameter } from '../apiRequestModels/requestParameter.model';
import { APISecurity } from '../apiSecurity/apiSecurity.model';
import { APIEndpoints } from './apiEndpoints.model';
export class APIMetaInfo {
  apiTitle: string;
  apiDescription: string;
  projectId: number;
  apiId: number;
  apiServerURL: string;
  apiAuthentication: string;
  apiEndpoints: APIEndpoints[];
  apiRequestParameters: RequestParameter[];
  apiSecuritySchemes: APISecurity[];
  constructor(
    apiTitle: string,
    apiDescription: string,
    projectId: number,
    apiId: number,
    apiServerURL: string,
    apiAuthentication: string,
    apiEndpoints: APIEndpoints[],
    apiRequestParameters: RequestParameter[],
    apiSecuritySchemes: APISecurity[]
  ) {
    this.apiTitle = apiTitle;
    this.apiDescription = apiDescription;
    this.projectId = projectId;
    this.apiId = apiId;
    this.apiServerURL = apiServerURL;
    this.apiAuthentication = apiAuthentication;
    this.apiEndpoints = apiEndpoints;
    this.apiRequestParameters = apiRequestParameters;
    this.apiSecuritySchemes = apiSecuritySchemes;
  }
}
