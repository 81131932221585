import { useMsal } from '@azure/msal-react';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { useSelector } from 'app/store';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function UserMenu() {
  const user = useSelector((state) => state.authSlice.user);
  const [userMenu, setUserMenu] = useState<null | HTMLElement>(null);
  const { instance } = useMsal();

  const userMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const userLogout = () => {
    instance.logout();
    localStorage.clear();
  };

  return (
    <>
      <Button className="flex gap-2" onClick={userMenuClick} color="inherit">
        <div className="hidden md:flex flex-col items-end">
          <Typography className="font-semibold normal-case">{user.name}</Typography>
          <Typography variant="subtitle2" fontSize="10px" className="normal-case">
            {user.email}
          </Typography>
        </div>
        <Avatar alt={user.name} src="">
          {user?.name?.[0] ?? 'A'}
        </Avatar>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-4',
        }}
      >
        <MenuItem component={Link} to="/MyProfile" onClick={userMenuClose} role="button">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="View Profile" />
        </MenuItem>
        <MenuItem onClick={userLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserMenu;
