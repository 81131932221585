import { createAsyncThunk } from '@reduxjs/toolkit';
import componentdesignspecService from 'app/services/app-designer/componentdesignspec.service';

export const uploadOASFile = createAsyncThunk(
  'openApi/uploadOASFile',
  async (data: WithInstance<{ formData: any; appId: string }>) => {
    const response = await componentdesignspecService.convertOpenAPIToDesignSpec(
      data.instance,
      data.formData,
      data.appId
    );
    return response.data;
  }
);
