import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showInfoMessage } from 'app/layouts/store/slices/message.slice';
import ImageService from 'app/services/mra-management/image.service';
import MRAService from 'app/services/mra-management/mra.service';

export const fetchMRAs = createAsyncThunk(
  'mraGallery/fetchMRAs',
  async (
    {
      instance,
      page,
      tenantId,
      previousFilter,
    }: ActionPayload<{
      page: number;
      tenantId: string;
      previousFilter: {
        searchText: string;
        tags: string[];
        mraType: string;
      };
    }>,
    thunkAPI
  ) => {
    let noMoreDocs = false;
    const queryParams = new URLSearchParams({
      type: previousFilter.mraType,
      page: `${page}`,
      tags: previousFilter.tags.toString(),
      searchText: previousFilter.searchText,
    });
    if (previousFilter.mraType === 'private') queryParams.set('tenantId', tenantId);
    const response = await MRAService.fetchFilteredMRAs(instance, queryParams);
    if (response.data.length === 0) {
      thunkAPI.dispatch(showInfoMessage('No MRAs available'));
    }
    noMoreDocs = response.data.length < 6;
    return {
      data: response.data,
      previousFilter,
      noMoreDocs,
    };
  }
);

export const getAllSearchableTags = async (
  instance: IPublicClientApplication,
  tenantId: string
) => {
  const response = await MRAService.getAllSearchableTags(instance, tenantId);
  return response.data;
};

export const getImageSrc = async (instance: IPublicClientApplication, imageId: string) => {
  const queryParams = new URLSearchParams({
    imageId,
  });
  const response = await ImageService.getImageSrc(instance, queryParams);
  return response.data;
};
