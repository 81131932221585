import { IPublicClientApplication } from '@azure/msal-browser';
import { INonMandatoryDetails, Services } from 'app/models';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'management';

class NonMandatoryDetailsService extends RequestHandler {
  endpath = 'nonMandatoryPrjDetails';
  getAllNonMandatoryProjDetails(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<INonMandatoryDetails>(`/${this.endpath}/`);
  }
}

export default new NonMandatoryDetailsService(apiServiceName);
