import { combineReducers } from '@reduxjs/toolkit';
import componentReducer from './slices/component.slices';
import entityReducer from './slices/entity.slices';
import apibuilderReducer from './slices/apibuilder.slices';
import pipelinesReducer from './slices/pipelines.slice';
import openAPIReducer from '../openapi/store/dataSlice';

const reducer = combineReducers({
  component: componentReducer,
  entity: entityReducer,
  apiBuilder: apibuilderReducer,
  pipelines: pipelinesReducer,
  openApi: openAPIReducer,
});

export default reducer;
