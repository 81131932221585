import { createAsyncThunk } from '@reduxjs/toolkit';
import applicationService from 'app/services/app-designer/application.service';
import applicationUserService from 'app/services/app-designer/application-user.service';
import applicationPipelineConfigService from 'app/services/pipeline/application-pipeline-config.service';
import componentService from 'app/services/app-designer/component.service';
import componentPipelineConfig from 'app/services/pipeline/component-pipeline-config.service';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { IApplicationPipelineConfig } from 'app/models/pipeline/application-pipeline-config.model';
import recentapplicationsService from 'app/services/app-designer/recent-applications.service';
import githubService from 'app/services/app-designer/github.service';
import { AxiosResponse } from 'axios';
import { ICreatePrStatusResponse, IGetCreatePrResponse } from 'app/models';
import azureDevopsService from 'app/services/app-designer/azure-devops.service';
import { VCProviders } from '../../types';
import appcommitlogsService from 'app/services/app-designer/appcommitlogs.service';

export const fetchApplication = createAsyncThunk(
  'applicationStore/fetchApplication',
  async (data: ActionPayload<{ applicationId: string }>) => {
    const response = await applicationService.getApplication(data.instance, data.applicationId);
    return response.data;
  }
);

export const fetchApplicationComponents = createAsyncThunk(
  'applicationSlice/fetchApplicationComponents',
  async (data: WithInstance<{ applicationId: string }>) => {
    try {
      const response = await componentService.getApplicationComponents(
        data.instance,
        data.applicationId
      );
      return response.data;
    } catch {
      return [];
    }
  }
);

export const pushAppCode = createAsyncThunk(
  'applicationSlice/pushAppCode',
  async (
    data: WithInstance<{
      applicationId: string;
      commitMessage: string;
      provider: VCProviders;
    }>
  ) => {
    // try {

    // } catch {
    //   return;
    // }
    const response: AxiosResponse<IGetCreatePrResponse, any> = await applicationService.pushCode(
      data.instance,
      data.applicationId,
      data.commitMessage
    );

    // if (data.provider === 'github') {

    // } else {
    //   response = await azureDevopsService.createAzPullRequest(
    //     data.instance,
    //     data.applicationId,
    //     data.commitMessage
    //   );
    // }
    return response.data;
  }
);

// export const getPRstatus = createAsyncThunk(
//   'applicationSlice/getPRstatus',
//   async (data: WithInstance<{ instanceId: string }>) => {
//     // try {

//     // } catch {
//     //   return;
//     // }
//     const response = await githubService.getPRstatus(data.instance, data.instanceId);
//     return response.data;
//   }
// );

export const pushAppCodeStatus = createAsyncThunk(
  'applicationSlice/getPRstatus',
  async (
    data: ActionPayload<{
      appId: string;
    }>,
    thunkAPI
  ) => {
    // let response: AxiosResponse<ICreatePrStatusResponse, any>;
    // if (data.provider === 'github') {
    //   response = await githubService.getPRstatus(data.instance, data.instanceId);
    // } else {
    //   response = await azureDevopsService.getAzPRstatus(data.instance, data.instanceId);
    // }
    // return response.data;
    const response = await applicationService.pushCodeStatus(data.instance, data.appId);
    return response.data;
  }
);

// export const checkPRStatus = createAsyncThunk(
//   'applicationSlice/checkPrStatus',
//   async (data: ActionPayload<{ appId: string }>, thunkAPI) => {
//     const response = await applicationService.checkPrStatus(data.instance, data.appId);
//     return response.data;
//   }
// );

export const getApplicationComponents = createAsyncThunk(
  'applicationStore/getApplicationComponents',
  async (data: ActionPayload<{ appId: string }>, thunkAPI) => {
    try {
      const response = await componentService.getAllComponents(data.instance, data.appId);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot load components'));
      return [];
    }
  }
);

export const getAppCommitLogs = createAsyncThunk(
  'applicationStore/getAppCommitLogs',
  async (data: ActionPayload<{ appId: string }>, thunkAPI) => {
    try {
      const response = await appcommitlogsService.getAppCommitLogs(data.instance, data.appId);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot load app commit logs'));
      return [];
    }
  }
);

export const fetchChanges = createAsyncThunk(
  'applicationStore/fetchChanges',
  async (data: ActionPayload<{ applicationId: string }>) => {
    const response = await applicationService.getGitCurrentChanges(
      data.instance,
      data.applicationId
    );
    return response.data;
  }
);

export const checkIfUserIsAuthorized = createAsyncThunk(
  'applicationStore/checkIfUserIsAuthorized',
  async (data: WithInstance<{ appId: string }>) => {
    try {
      const response = await applicationUserService.checkIfUserIsAuthorized(
        data.instance,
        data.appId
      );
      return response.data.status;
    } catch (error) {
      return false;
    }
  }
);

export const getComponentPipelineConfigByAppId = createAsyncThunk(
  'applicationStore/getComponentPipelineConfigByAppId',
  async (data: ActionPayload<{ appId: string }>, thunkAPI) => {
    try {
      const response = await componentPipelineConfig.getComponentPipelineConfigByAppId(
        data.instance,
        data.appId
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot load Component Pipelines'));
      return [];
    }
  }
);

export const getApplicationPipelineConfig = createAsyncThunk(
  'applicationStore/getApplicationPipelineConfig',
  async (data: ActionPayload<{ appId: string }>, thunkAPI) => {
    try {
      const response = await applicationPipelineConfigService.getPipelineConfig(
        data.instance,
        data.appId
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot load pipeline details'));
      return {} as IApplicationPipelineConfig;
    }
  }
);
export const getApplicationUsers = createAsyncThunk(
  'applicationStore/getApplicationUsers',
  async (data: ActionPayload<{ appId: string }>, thunkAPI) => {
    const response = await applicationService.findUsers(data.instance, data.appId);
    console.log(response);
    const appUsers: any = {};
    for (const appUser of response.data.users) {
      appUsers[appUser._id] = appUser;
    }
    return appUsers;
  }
);

export const assignOrUpdateUserRoleToApplication = createAsyncThunk(
  'applicationStore/assignOrUpdateUserRoleToApplication',
  async (data: ActionPayload<{ appId: string; roleIds: any }>, thunkAPI) => {
    try {
      await applicationService.assignOrUpdateUserRoleToApplication(
        data.instance,
        data.appId,
        data.roleIds
      );
      thunkAPI.dispatch(showSuccessMessage('Adding user to application is  successful'));
    } catch (error) {
      console.log(error);
      thunkAPI.dispatch(showErrorMessage('error'));
      return error;
    }
  }
);

export const revokeUserRoleFromApplication = createAsyncThunk(
  'applicationStore/revokeUserRoleFromApplication',
  async (data: ActionPayload<{ appId: string; userId: any }>, thunkAPI) => {
    try {
      await applicationService.revokeUserRoleFromApplication(
        data.instance,
        data.appId,
        data.userId
      );
      thunkAPI.dispatch(showSuccessMessage(' Revoking user is successful'));
    } catch (error) {
      console.log(error);
      thunkAPI.dispatch(showErrorMessage('error'));
      return error;
    }
  }
);
export const createOrUpdate = createAsyncThunk(
  'dashboard/createOrUpdate',
  async (data: WithInstance<{ appId: string }>) => {
    const response = await recentapplicationsService.createOrUpdate(data.instance, data.appId);
    return response.data;
  }
);

export const generateAccessToken = createAsyncThunk(
  'dashboard/generateAccessToken',
  async (data: WithInstance<{ tokenData: any }>, thunkAPI) => {
    try {
      const response = await applicationUserService.generateAccessToken(
        data.instance,
        data.tokenData
      );
      thunkAPI.dispatch(showSuccessMessage('Token generated successfully'));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Token generation failed'));
      return error;
    }
  }
);
