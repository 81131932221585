import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApplication } from 'app/models';
import { getApplicationsOfUser } from '../actions/apps.actions';

type InitialState = {
  applications: IApplication[];
  loading: boolean;
};

const initialState: InitialState = {
  applications: [],
  loading: true,
};

const appsSlice = createSlice({
  name: 'appsSlice',
  initialState,
  reducers: {
    deleteApplication: (state, action: PayloadAction<string>) => {
      state.applications = state.applications.filter((app) => app._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationsOfUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicationsOfUser.fulfilled, (state, action) => {
        state.applications = action.payload;
        state.loading = false;
      });
  },
});

export const { deleteApplication } = appsSlice.actions;

export default appsSlice.reducer;
