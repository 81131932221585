import React, { useEffect } from 'react';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import { getOnlineNavigation } from 'app/configs/navigation.config';
// @ts-ignore
import SwaggerEditorBundle from './swagger-editor-dist/swagger-editor-bundle';
// @ts-ignore
import SwaggerEditorStandalonePreset from './swagger-editor-dist/swagger-editor-standalone-preset';
import './swagger-editor-dist/swagger-editor.css';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import ApiService from 'app/services/fileupload.service';

const APIEditorDesign = () => {
  const { instance } = useMsal();
  const { viewApiId } = useParams<{ viewApiId: string }>();

  const authorizedNavigation = useNavAuthorization();
  useEffect(() => {
    authorizedNavigation(getOnlineNavigation());

    const editor = SwaggerEditorBundle({
      dom_id: '#swagger-editor',
      layout: 'StandaloneLayout',
      presets: [SwaggerEditorStandalonePreset],
      swagger2GeneratorUrl: null, // <-----
      oas3GeneratorUrl: null,
      url: '',
    });

    if (viewApiId != '') {
      console.log('API ID : ', viewApiId);
      ApiService.getAPIDetailsByAPIId(instance, viewApiId)
        .then((response: any) => {
          console.log('API Spec Data : ' + response?.data?.data[0]?.ApiSpec);
          editor.specActions.updateSpec(response?.data?.data[0]?.ApiSpec);
        })
        .catch((errorInstance) => {
          console.error('Error : ', errorInstance);
        });
    }
  });

  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'API Editor ',
      url: `/apifactory/apiEditorDesign`,
    },
  ];

  //     const logContent = () => {
  //       const content = window.localStorage.getItem('swagger-editor-content');
  //       console.log(content);
  //     };

  return (
    <div>
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <div id="swagger-editor"></div>
    </div>
  );
};

export default APIEditorDesign;
