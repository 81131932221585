import { BrowserAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, TextField, Typography } from '@mui/material';
import { showErrorMessage } from 'app/layouts/store/slices/message.slice';
import tenantManagementService from 'app/services/management/tenant-management.service';
import { useDispatch } from 'app/store';
import Logo from 'assets/logo-colored.svg';
import { isEmpty } from 'lodash';
import { FormEventHandler, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import AuthWrapper from '../components/LoginWrapper';

const validEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('You must enter a valid email')
    .email('You must enter a valid email')
    .matches(validEmail, 'You must enter a valid email'),
});

const defaultValues = {
  email: '',
};

function LogInPage() {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const email = watch('email');
  const { isValid, dirtyFields, errors } = formState;

  const [noDomain, setNoDomain] = useState(false);
  const [loginCheck, setLoginCheck] = useState(false);

  const loginUser: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const domain = email.split('@').pop();
    if (!domain) return;
    setLoginCheck(true);
    try {
      const { data: tenant } = await tenantManagementService.getTenantDetailsByDomain(
        instance,
        domain
      );
      localStorage.setItem('loggedIn', 'true');
      console.log(localStorage.getItem('loggedIn'));
      await instance.loginRedirect({
        domainHint: tenant.idp,
        loginHint: email,
        prompt: 'login',
        scopes: [],
      });
      navigate('/');
    } catch (error: unknown) {
      if (error instanceof BrowserAuthError) {
        dispatch(showErrorMessage('Authentication interrupted'));
      }
      setLoginCheck(false);
      setNoDomain(true);
    }
  };

  useEffect(() => {
    setNoDomain(false)
  }, [email])

  return (
    <AuthWrapper>
      <Card id="login-card">
        <header>
          <img src={Logo} alt="Hexaware" />
          <Typography className="header">RapidX</Typography>
        </header>
        <form onSubmit={loginUser} className="grid w-full gap-4">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                error={!!errors.email || noDomain}
                id="email-field"
                label="Enter your Email address"
                helperText={
                  errors?.email?.message ||
                  (noDomain ? 'Your domain is not registered with the RapidX platform' : null)
                }
              />
            )}
          />
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loginCheck}
            loadingIndicator="Please wait..."
            disabled={isEmpty(dirtyFields) || !isValid || loginCheck}
            onClick={() => {
              setNoDomain(false);
            }}
          >
            Login
          </LoadingButton>
          <Typography className="font-light">
            {`Don't have an account?`}{' '}
            <Link to="/register" className="font-bold">
              Sign Up
            </Link>
          </Typography>
        </form>
      </Card>
      <Box id="description">
        <header>Welcome to RapidX</header>
        <span>
          {`Hexaware's Software Development engineering platform (SWeDP codenamed - Rapidx) helps increase the developer velocity and reduce development effort for building applications. The platform will help templatize clean architecture for enteprise-grade micro apps, API, front-end and backend that delivers digital business use cases. It allows developers to choose from a catalogue of prebuilt templates for front-end, backend, and databases, accelerating the initial foundation generation for various architecture patterns.`}
        </span>
      </Box>
    </AuthWrapper>
  );
}

export default LogInPage;
