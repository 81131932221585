import { createAsyncThunk } from '@reduxjs/toolkit';
import applicationService from 'app/services/app-designer/application.service';

export const getAppChanges = createAsyncThunk(
  'navigationSlice/getAppChanges',
  async (data: WithInstance<{ appId: string; badgeId: string }>) => {
    const response = await applicationService.getGitCurrentChangesCount(data.instance, data.appId);
    return response.data;
  }
);
