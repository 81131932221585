import { IPublicClientApplication } from '@azure/msal-browser';
import { Services } from 'app/models';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'iam';

class LocationService extends RequestHandler {
  endpath = 'location';

  getLocationsByTenant(instance: IPublicClientApplication, tenantId: string) {
    return this.requestHandler(instance).get(`/${this.endpath}/public/tenant/${tenantId}`);
  }
}

export default new LocationService(apiServiceName);
