import { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogContent,
  Typography,
} from '@mui/material';
import ApiService from 'app/services/fileupload.service';
import { useMsal } from '@azure/msal-react';
import useDialog from '../hooks/popup';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/layouts/store/slices/dialog.slice';

function EditAccountAdmin({ modelData, onCloseDialog }: any) {
  const { instance } = useMsal();
  const [id, setAccountid] = useState(modelData.id || '');
  const [name, setAccountName] = useState(modelData.name || '');
  const [description, setDescription] = useState(modelData.description || '');
  const [vertical, setVertical] = useState(modelData.vertical || '');
  const [geography, setGeography] = useState(modelData.geography || '');
  const [serviceLine, setServiceLine] = useState(modelData.serviceLine || '');

  const [error, setError] = useState<string | null>(null);

  const { showDialog } = useDialog();
  const dispatch = useDispatch();
  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const form = {
      id,
      name,
      description,
      vertical,
      geography,
      serviceLine,
    };

    ApiService.updateAccountDetails(instance, form)
      .then((response) => {
        const responseData = JSON.parse(response.data);
        if (responseData.code >= 200 && responseData.code < 210) {
          console.log('response', responseData.status);

          showDialog('Account Details Updated successfully ');
          dispatch(showSuccessMessage('Account Details Updated successfully'));
          dispatch(closeDialog());
          onCloseDialog();
        } else {
          setError(`Request failed with status code ${responseData.code}`);
          showDialog(
            'Something went wrong , Please try after sometime',
            <p>{responseData.message}</p>
          );
          dispatch(showErrorMessage(<p>{responseData.message}</p>));
        }
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);

        let errorMessage: string;
        if (errorInstance.message.includes('Network Error')) {
          errorMessage = 'Something went wrong , Please try after sometime.';
        } else {
          errorMessage = `Request failed: ${errorInstance.responseData.message}`;
        }

        setError(errorInstance);
        showDialog('Error', <p>{errorMessage}</p>);
        dispatch(showErrorMessage(<p>{errorMessage}</p>));
      });
  };

  return (
    <DialogContent>
      <form onSubmit={handleFormSubmit}>
        <Typography variant="h5">Update Account Details</Typography>
        {/* <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
          <Button
            type="submit"
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            style={{ marginLeft: '420px' }}
          >
            Update
          </Button>

        </div> */}
        <TextField
          label="Account Name"
          value={name}
          inputProps={{ maxLength: 20 }}
          onChange={(e) => setAccountName(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          style={{ width: '100%', marginBottom: '20px' }}
          label="Description"
          inputProps={{ maxLength: 40 }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
        />

        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Select Vertical</InputLabel>
          <Select
            value={vertical}
            label="Select Vertical"
            onChange={(e) => setVertical(e.target.value)}
          >
            <MenuItem value="banking">Banking</MenuItem>
            <MenuItem value="fs">Financial Services</MenuItem>
            <MenuItem value="HI">Healthcare and Insurance</MenuItem>
            <MenuItem value="MC">Manufacturing and Consumer</MenuItem>
            <MenuItem value="HTPS">High Tech Professional Services</MenuItem>
            <MenuItem value="GTT">Global Travel and Transport</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Select Geography</InputLabel>
          <Select
            value={geography}
            label="Select Geography"
            onChange={(e) => setGeography(e.target.value)}
          >
            <MenuItem value="NorthAmerica">North America</MenuItem>
            <MenuItem value="eu">EU</MenuItem>
            <MenuItem value="apac">APAC</MenuItem>
            <MenuItem value="mena">MENA</MenuItem>
            <MenuItem value="emea">EMEA</MenuItem>
            <MenuItem value="NA">None</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Service Line</InputLabel>
          <Select
            value={serviceLine}
            label="Service Line"
            onChange={(e) => setServiceLine(e.target.value)}
          >
            <MenuItem value="dct">DCT</MenuItem>
            <MenuItem value="dpe">DPE</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" size="small" type="submit" onClick={handleFormSubmit}>
          Update
        </Button>
      </form>{' '}
    </DialogContent>
  );
}

export default EditAccountAdmin;
