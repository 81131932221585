/*eslint import/no-cycle: [2, { maxDepth: 2 }]*/
import { RequestHandler } from 'libs/request-handler';
import { IPublicClientApplication } from '@azure/msal-browser';
import { IAudit, Services } from 'app/models';

const apiServiceName: Services = 'audit-management';

class AuditManagementService extends RequestHandler {
  endpath = 'audits';

  getAllAudits(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<IAudit[]>(`/${this.endpath}`);
  }

  getAuditsById(instance: IPublicClientApplication, queryParams: URLSearchParams) {
    return this.requestHandler(instance).get<IAudit[]>(`/auditsById?${queryParams.toString()}`);
  }
}

export default new AuditManagementService(apiServiceName);
