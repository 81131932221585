import { IPublicClientApplication } from '@azure/msal-browser';
import { IEntity } from 'app/models';
import { RequestHandler } from 'libs/request-handler';
import { AxiosRequestConfig } from 'axios';

const apiServiceName = 'app-designer';

class EntityService extends RequestHandler {
  endpath = 'entity';

  getComponentEntities(instance: IPublicClientApplication, compId?: string, appId?: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get(
      `/${this.endpath}/getComponentEntities/${compId}`,
      config
    );
  }

  deleteEntity(instance: IPublicClientApplication, entityId: string, appId?: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).delete(`/${this.endpath}/${entityId}`, config);
  }

  addEntity(instance: IPublicClientApplication, details: any) {
    const headers = { applicationid: details.appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(`/${this.endpath}/createEntities`, details, config);
  }

  updateEntity(instance: IPublicClientApplication, entity: IEntity) {
    const headers = { applicationid: entity.appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(`/${this.endpath}/update`, entity, config);
  }

  addEntitywithNestedRelations(
    instance: IPublicClientApplication,
    entity: IEntity,
    entityType?: string,
    componentId?: string,
    appId?: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `/${this.endpath}/designspec/${componentId}/${entityType}`,
      entity,
      config
    );
  }
}

export default new EntityService(apiServiceName);
