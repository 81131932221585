import { Dialog, Typography, DialogContent } from '@mui/material';
import { useSelector } from '../../store';
import Lottie from 'lottie-react';
import LottieConfig from 'app/configs/lottie.config';

function InProcessDialog() {
  const { open, title, animationKey } = useSelector((state) => state.layoutSlice.IPDialogSlice);

  return (
    <Dialog open={!!open} classes={{ paper: 'm-24' }} fullWidth maxWidth="sm">
      <DialogContent style={{ overflow: 'hidden' }}>
        <div className="flex flex-col items-center justify-center">
          <Typography className="text-lg mb-5 font-bold">{title}</Typography>
          <div className="w-192 sm:w-320 max-w-full rounded-2">
            <Lottie animationData={LottieConfig[animationKey]} loop={true} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default InProcessDialog;
