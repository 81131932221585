import { Box, Button, TextField, Typography } from '@mui/material';
import Logo from 'assets/logo-colored.svg';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'app/store';
import RegistrationWrapper from '../components/RegistrationWrapper';
import { showMessage } from 'app/layouts/store/slices/message.slice';
import userRequestService from 'app/services/iam/user-request.service';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { memo, useEffect, useState } from 'react';
import { checkTenantByDomain } from 'app/main/dashboard/store/actions/users.actions';
import { getLocationsByTenant } from 'app/main/dashboard/store/actions/users.actions';
import {
  closeProcessDialog,
  openProcessDialog,
} from 'app/layouts/store/slices/in-process-dialog.slice';
import { AxiosError } from 'axios';
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from 'app/layouts/store/slices/message.slice';
import _ from 'lodash';
import userService from 'app/services/iam/user.service';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

const defaultValues = {
  firstName: '',
  secondName: '',
  lastName: '',
  email: '',
  department: '',
};

const validEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const noSpaceStringRegex = /^\S*$/;
const textRegExp = /^[a-zA-Z\s]{0,}$/;

function RegistrationPage() {
  const { instance } = useMsal();

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required('FIRST_NAME_REQUIRED')
      .matches(textRegExp, 'FIRST_NAME_ALPHABETS') // /[a-zA-Z]{1,}/
      .max(50, 'FIRST_NAME_MAX'),
    secondName: yup
      .string()
      .trim()
      .matches(textRegExp, 'MIDDLE_NAME_ALPHABETS')
      .max(50, 'MIDDLE_NAME_MAX'),
    lastName: yup
      .string()
      .trim()
      .required('LAST_NAME_REQUIRED')
      .matches(textRegExp, 'LAST_NAME_ALPHABETS')
      .max(50, 'LAST_NAME_MAX'),
    email: yup
      .string()
      .trim()
      .required('EMAIL_REQUIRED')
      .email('EMAIL_FORMAT')
      .matches(validEmail, 'EMAIL_FORMAT')
      .max(50, 'EMAIL_MAX'),
    department: yup
      .string()
      .trim()
      .required('DEPARTMENT_REQUIRED')
      .matches(textRegExp, 'DEPARTMENT_ALPHABETS')
      .max(50, 'DEPARTMENT_MAX'),
  });

  const dispatch = useDispatch();

  const { control, watch, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const firstName = watch('firstName');
  const secondName = watch('secondName');
  const lastName = watch('lastName');
  const email = watch('email');
  const department = watch('department');

  const { isValid, dirtyFields, errors } = formState;

  // store data
  const fullLocationData = useSelector((state) => state.authSlice.locationData.locations);
  const locationData = useSelector((state) => state.authSlice.locationData.data);
  // const locationData = ['Hyderabad', 'Chennai', 'Bangalore'];

  const [noDomain, setNoDomain] = useState(false);
  const [tenant, setTenant] = useState();

  // workphone states
  const [workPhone, setWorkPhone] = useState('');
  const [validPhone, setValidPhone] = useState(true);
  const [phoneCountry, setPhoneCountry] = useState('us');
  const [workPhoneError, setWorkPhoneError] = useState('');
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);
  const [isWorkPhoneValid, setIsWorkPhoneValid] = useState(false);

  //location states
  const [inputValue, setInputValue] = useState('');
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');
  const [isLocationDisabled, setIsLocationDisabled] = useState(true);

  const handleLocation = () => {
    const locationObj = fullLocationData.find((l: any) => l.totalLocation === inputValue);
    console.log(locationObj);
    return locationObj;
  };

  const handleChange = (newValue: string) => {
    const validphone = matchIsValidTel(newValue);
    setValidPhone(validphone);
    setWorkPhone(newValue);
  };

  function checkStatus(resObj: any) {
    console.log('status check');
    if (resObj.payload?.status === 200) {
      const tenantID = resObj.payload?.result?.response?._id;
      setTenant(tenantID);
      setNoDomain(false);
      // Getting Location Data by Tenant Domain
      dispatch(getLocationsByTenant({ tenantId: tenantID, instance: instance })).then(
        (res: any) => {
          if (res?.status === 404) {
            setIsLocationDisabled(true);
          } else {
            setIsLocationDisabled(false);
          }
        }
      );
    } else {
      setNoDomain(true);
      setIsLocationDisabled(true);
    }
  }

  async function onSubmit(data: any) {
    const finalL = await handleLocation();

    const finalData = {
      firstname: firstName.trim(),
      secondname: secondName.trim(),
      lastname: lastName.trim(),
      workphone: workPhone,
      email: email.trim().toLowerCase(), // split("@")[0] + "@" + domainName,
      department: department.trim(),
      location: finalL._id,
      tenant: tenant,
      status: 'Pending',
    };
    console.log('Data Submitted', finalData);
    const existingUserRequest = await userRequestService.getRequestByEmail(
      finalData.email,
      instance
    );
    const existingUser = await userService.getUserByEmail(finalData.email, instance);
    if (existingUserRequest.data === '' && existingUser.data === '') {
      try {
        const response = await userRequestService.postUserRequest(finalData, instance);
        if (response.status === 201) {
          dispatch(showSuccessMessage('User Registered Successfully To the system'));
          dispatch(closeProcessDialog());
          reset(defaultValues);
          setIsPhoneTouched(false);
          setWorkPhone('+1');
          setIsWorkPhoneValid(true);
          setInputValue('');
          setCurrentLocation('');
          setIsLocationChanged(false);
          setIsLocationDisabled(true);
        }
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          console.log(error.message);
          dispatch(showErrorMessage('Unable to register the user, please try after sometime'));
        }
      }
    } else if (existingUser.data !== '' && existingUserRequest.data === '') {
      dispatch(showErrorMessage('User already exists in the system!'));
    } else {
      dispatch(showErrorMessage('User already registered in the system...waiting for approval!'));
    }
  }

  useEffect(() => {
    if (validEmail.test(email)) {
      setIsLocationChanged(false);
      setInputValue('');
      setCurrentLocation('');
      setTimeout(function () {
        dispatch(checkTenantByDomain({ email: email.toLowerCase(), instance: instance }))
          .then((res) => {
            console.log('Res status', res);
            checkStatus(res);
          })
          .catch((err: any) => {
            dispatch(
              showMessage({
                message: 'Something Went Wrong',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'error',
              })
            );
          });
      }, 100);
    } else {
      setIsLocationDisabled(true);
    }
  }, [email]);

  const showError = (condition: any) => condition && { error: true };
  const showErrorHelperText = (condition: any, errorCode: any) =>
    condition && { helperText: errorCode };

  return (
    <RegistrationWrapper>
      <section>
        <div className="container">
          <header>
            <img src={Logo} className="mr-5" alt="Hexaware Logo" />
            <h1>RapidX</h1>
          </header>
          <Box className="flex flex-col justify-center">
            {/* <Box className="flex gap-5"> */}
            <form
              name="registerForm"
              noValidate
              autoComplete="off"
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                key="email"
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    key="email"
                    id="email"
                    className="mb-5"
                    type={'email'}
                    label="Email"
                    // inputProps={{ maxLength: 50 }}
                    size="small"
                    value={email}
                    variant="outlined"
                    error={!!errors.email || noDomain}
                    helperText={errors?.email?.message || (noDomain ? 'EMAIL_NOTIN_DOMAIN' : null)}
                    {...showError(email.length !== 0 && !noSpaceStringRegex.test(email))}
                    {...showErrorHelperText(
                      email.length !== 0 && !noSpaceStringRegex.test(email),
                      'EMAIL_FORMAT'
                    )}
                    fullWidth
                    autoFocus
                    required
                  />
                )}
              />
              <Box className="grid grid-cols-1 md:grid-cols-3 gap-2">
                <Controller
                  key={'firstName'}
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="firstName"
                      className="mb-5"
                      label="First Name"
                      type="text"
                      key={'firstName'}
                      inputProps={{ pattern: '[a-z]' }}
                      size="small"
                      value={firstName}
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />

                <Controller
                  key={'secondName'}
                  control={control}
                  name="secondName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="secondName"
                      key={'secondName'}
                      className="mb-5"
                      label="Middle Name"
                      // inputProps={{ maxLength: 50 }}
                      size="small"
                      value={secondName}
                      error={!!errors.secondName}
                      helperText={errors?.secondName?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                <Controller
                  key={'lastName'}
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="lastName"
                      key={'lastName'}
                      className="mb-5"
                      label="Last Name"
                      // inputProps={{ maxLength: 50 }}
                      size="small"
                      value={lastName}
                      variant="outlined"
                      error={!!errors.lastName}
                      helperText={errors?.lastName?.message}
                      fullWidth
                      required
                    />
                  )}
                />
              </Box>
              {/* <FormControl className="mb-5" sx={{ width: '100%' }}>
                <PhoneInput
                  key="workphone"
                  inputProps={{
                    name: 'Mobile Number',
                    required: true,
                  }}
                  countryCodeEditable={false}
                  country={'us'}
                  placeholder=""
                  value={workPhone}
                  enableSearch
                  searchPlaceholder="Search"
                  inputStyle={{
                    height: '40px',
                    width: '100%',
                    fontSize: '13px',
                  }}
                  onKeyDown={() => setIsPhoneTouched(true)}
                  onChange={(_value: any, _country: any, _e: any, formattedValue: any) => {
                    if (phoneCountry === _country?.countryCode) {
                      setWorkPhone(formattedValue);
                    } else {
                      setIsPhoneTouched(false);
                      setPhoneCountry(_country.countryCode);
                      setWorkPhone(_country.dialCode);
                    }
                  }}
                  searchNotFound="Country not Found"
                  isValid={(value: any, country: any) => {
                    const condition0 =
                      value.slice(country.countryCode?.length)?.length >= 1 &&
                      value.slice(country.countryCode?.length)?.length <= 5;
                    // let condition1 = value.slice(country.countryCode?.length)?.length >= 10;
                    const condition2 = value.slice(country.countryCode?.length)?.length <= 15;

                    console.log('Phone Data:', value, country.countryCode, condition0, condition2);

                    if (
                      (value.length === 0 || value.length <= country.countryCode?.length) &&
                      isPhoneTouched
                    ) {
                      setIsWorkPhoneValid(false);
                      setWorkPhoneError('MOBILE_REQUIRED');
                      return false;
                    } else if (value.charAt(country.dialCode?.length) === '0') {
                      setIsWorkPhoneValid(false);
                      setWorkPhoneError('MOBILE_FIRSTDIGIT_ZERO');
                      return false;
                    } else if (value.length > country.countryCode?.length && condition0) {
                      setIsWorkPhoneValid(false);
                      setWorkPhoneError('MOBILE_INVALID');
                      return false;
                    }
                    // else if (value.length > country.countryCode?.length && !condition1) {
                    //   setIsWorkPhoneValid(false);
                    //   setWorkPhoneError(t("MOBILE_LEN_10"));
                    //   return false;
                    // }
                    else if (value.length > country.countryCode?.length && !condition2) {
                      setIsWorkPhoneValid(false);
                      setWorkPhoneError('MOBILE_LEN_15');
                      return false;
                    } else {
                      setIsWorkPhoneValid(isPhoneTouched);
                      setWorkPhoneError('');
                      return true;
                    }
                  }}
                />
                {workPhoneError !== '' && (
                  <>
                    <FormHelperText style={{ color: 'red' }}>{workPhoneError}</FormHelperText>
                    <br />
                  </>
                )}
              </FormControl> */}
              <FormControl className="mb-5" sx={{ width: '100%' }} error={!validPhone}>
                <MuiTelInput
                  label="Mobile Number"
                  fullWidth
                  value={workPhone}
                  onChange={handleChange}
                  defaultCountry="US"
                  error={!validPhone}
                  required
                />
                {!validPhone && <FormHelperText>Please enter a valid Phone number</FormHelperText>}
              </FormControl>

              <Controller
                control={control}
                name="department"
                render={({ field }) => (
                  <TextField
                    {...field}
                    // inputProps={{ maxLength: 50 }}
                    size="small"
                    className="mb-5"
                    label="Department"
                    value={department}
                    id="department"
                    variant="outlined"
                    error={!!errors.department}
                    helperText={errors?.department?.message}
                    fullWidth
                    required
                  />
                )}
              />

              <Autocomplete
                className="mb-5"
                inputValue={inputValue}
                value={currentLocation}
                size="small"
                onChange={(_event, newValue: any) => {
                  setIsLocationChanged(true);
                  setCurrentLocation(newValue);
                }}
                onInputChange={(_event, newInputValue) => {
                  setIsLocationChanged(true);
                  setInputValue(newInputValue);
                }}
                id="location"
                disabled={isLocationDisabled}
                options={locationData}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...(inputValue === '' && isLocationChanged ? { error: true } : null)}
                    {...(inputValue === '' && isLocationChanged
                      ? {
                          helperText:
                            // t("LOCATION_REQUIRED")
                            'Please Add A Location',
                        }
                      : null)}
                    label="Location *"
                  />
                )}
              />

              <Button
                variant="contained"
                color="secondary"
                className="w-full mt-5"
                aria-label="Register"
                disabled={
                  _.isEmpty(dirtyFields) || !isValid || !isWorkPhoneValid || inputValue === ''
                }
                type="submit"
                size="large"
              >
                Register
              </Button>
            </form>
          </Box>
          <div className="font-bold flex items-baseline mt-2 justify-center">
            <Typography>Already have an account?</Typography>
            <Link className="ml-4" to="/login">
              Login
            </Link>
          </div>
        </div>
      </section>
    </RegistrationWrapper>
  );
}

export default RegistrationPage;
