import { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Radio,
  DialogContent,
  FormGroup,
  FormLabel,
  Typography,
  RadioGroup,
} from '@mui/material';
import ApiService from 'app/services/fileupload.service';
import { useMsal } from '@azure/msal-react';
import useDialog from '../hooks/popup';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'react-redux';
import { closeDialog, openDialog } from 'app/layouts/store/slices/dialog.slice';

function EditProjectAdmin({ modelData, onCloseDialog }: any) {
  const { instance } = useMsal();
  const [projectId, setProjectId] = useState(modelData.projectId || '');
  const [projectName, setProjectName] = useState(modelData.projectName || '');
  const [description, setDescription] = useState(modelData.description || '');
  const [repositoryName, setRepositoryName] = useState(modelData.repositoryName || '');
  const [userName, setUsername] = useState(modelData.userName || '');
  const [token, setToken] = useState(modelData.token || '');
  const [accountName, setAccountName] = useState(modelData.accountName || '');
  const [repository, setSelectedRepository] = useState(modelData.repository || '');
  const [error, setError] = useState<string | null>(null);
  const [accountNames, setAccountNames] = useState([]);

  const { showDialog } = useDialog();
  const dispatch = useDispatch();
  const [storage, setSelectedradioValue] = useState(modelData.storage || '');

  const handleStorageChange = (event: { target: { value: any } }) => {
    setSelectedradioValue(event.target.value);
  };

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const form = {
      projectId,
      projectName,
      description,
      accountName,
      repositoryName,
      userName,
      token,
      repository,
      storage,
    };

    ApiService.updateProjectDetails(instance, form)
      .then((response) => {
        const responseData = JSON.parse(response.data);
        if (responseData.code >= 200 && responseData.code < 210) {
          console.log('response', responseData.status);

          showDialog('Project Details Updated successfully ');
          dispatch(showSuccessMessage('Project Details Updated successfully'));
          dispatch(closeDialog());
          onCloseDialog();
        } else {
          // Non-2xx status code

          setError(`Request failed with status code ${responseData.code}`);
          showDialog(
            'Something went wrong , Please try after sometime',
            <p>{responseData.message}</p>
          );
          dispatch(showErrorMessage(<p>{responseData.message}</p>));
        }
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);

        let errorMessage: string;
        if (errorInstance.message.includes('Network Error')) {
          errorMessage = 'Something went wrong , Please try after sometime.';
        } else {
          errorMessage = `Request failed: ${errorInstance.responseData.message}`;
        }

        setError(errorInstance);
        showDialog('Error', <p>{errorMessage}</p>);
        dispatch(showErrorMessage(<p>{errorMessage}</p>));
      });
  };

  const getAccountNameinDialog = () => {
    ApiService.getAccountName(instance)
      .then((response) => {
        console.log('responseAcc', response.data.data);
        setAccountNames(response?.data?.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      });
  };

  useEffect(() => {
    getAccountNameinDialog();
  }, []);

  const handleAccountNameChange = (e: { target: { value: any } }) => {
    setAccountName(e.target.value);
  };

  return (
    <DialogContent>
      <form onSubmit={handleFormSubmit}>
        <Typography variant="h5">Update Project Details</Typography>
        {/* <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
          <Button
            type="submit"
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            style={{ marginLeft: '420px' }}
          >
            Update Project
          </Button>
        </div> */}

        <TextField
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled
        />
        <TextField
          style={{ width: '100%', marginBottom: '20px' }}
          label="Description"
          inputProps={{ maxLength: 40 }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Account Name</InputLabel>
          <Select value={accountName} label="Account Name" onChange={handleAccountNameChange}>
            {accountNames.map((account: { id: number; name: string }) => (
              <MenuItem key={account.id} value={account.name}>
                {account.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormLabel required component="legend">
            Storage
          </FormLabel>
          <RadioGroup
            style={{ flexDirection: 'row' }}
            value={storage}
            onChange={handleStorageChange}
          >
            <FormControlLabel
              value="local"
              control={<Radio />}
              label="Local"
              style={{ marginRight: '20px' }}
            />
            <FormControlLabel value="repository" control={<Radio />} label="Repository" />
          </RadioGroup>
        </FormControl>

        <TextField
          style={{ width: '100%', marginBottom: '20px' }}
          label="Repository Name"
          inputProps={{ maxLength: 50 }}
          value={repositoryName}
          onChange={(e) => setRepositoryName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <FormControl style={{ width: '100%', marginBottom: '10px' }}>
          <InputLabel>Select Repository</InputLabel>
          <Select
            value={repository}
            label="Select Repository"
            onChange={(e) => setSelectedRepository(e.target.value)}
          >
            <MenuItem value="GIT">GIT</MenuItem>
            <MenuItem value="SVN">SVN</MenuItem>
            <MenuItem value="BitBucket">BitBucket</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Username"
          value={userName}
          inputProps={{ maxLength: 50 }}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Token"
          type="password"
          inputProps={{ maxLength: 150 }}
          value={token}
          onChange={(e) => setToken(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" size="small" type="submit" onClick={handleFormSubmit}>
          Update Project
        </Button>
      </form>{' '}
    </DialogContent>
  );
}

export default EditProjectAdmin;
