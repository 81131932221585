import styled from '@emotion/styled';
import { Box } from '@mui/system';
import BGImage from 'assets/LandingPageBG.svg';

const AuthWrapper = styled(Box)`
  height: 100vh;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  background-image: url(${BGImage});
  background-repeat: no-repeat;
  backface-visibility: visible;
  background-size: cover;

  #login-card {
    display: grid;
    gap: 1rem;
    min-width: 25rem;
    max-height: 70vh;
    padding: 3rem;
    margin: 1rem;
    box-shadow: 20px 20px 50px -10px rgba(0, 0, 0, 0.8);

    header {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 1rem;

      .header {
        font-weight: 700;
        font-size: 3rem;
        color: rgb(46, 46, 46);
      }

      .header::after {
        content: '.';
        font-weight: 700;
        color: #0066ff;
      }
    }
  }

  #description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 4rem;

    header {
      font-weight: bolder;
      font-size: clamp(2rem, 1rem + 10vh, 4rem);
      color: white;
      line-height: 4rem;

      &::after {
        content: '!!';
        font-weight: bolder;
        color: #0066ff;
      }
    }

    span {
      color: white;
      font-size: 12px;
      text-align: justify;
      letter-spacing: 1px;
    }
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    #description {
      display: none;
    }
  }
`;

export default AuthWrapper;
