import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';

const Component = lazy(() => import('./Component'));

const ComponentDetails = lazy(() => import('./pages/ComponentDetails'));
const Functions = lazy(() => import('./pages/Functions'));
const SideBarWithContent = lazy(() => import('./apiBuilder/SideBarWithContent'));
const UploadSchemaLogic = lazy(() => import('./apiBuilder/UploadSchemaLogic'));
const Landingpage = lazy(() => import('./apiBuilder/LandingPage'));
const EntityDesigner = lazy(() => import('./entityDesigner/EntityDesigner'));
const UploadSchema = lazy(() => import('./entityDesigner/UploadSchema'));
const ReleasePipelines = lazy(() => import('./pages/ReleasePipelines'));
const BuildPipelines = lazy(() => import('./pages/BuildPipelines'));
const GenerateBusinessValidations = lazy(() => import('./pages/GenerateBusinessValidations'));
const GenerateCrud = lazy(() => import('./pages/GenerateCrud'));

const componentConfig: RouteObject[] = [
  {
    path: 'component/:componentId',
    element: <Component />,
    children: [
      {
        path: '',
        element: <ComponentDetails />,
      },
      {
        path: 'functions',
        element: <Functions />,
      },
      {
        path: 'apiBuilder/layer',
        element: <SideBarWithContent />,
      },
      {
        path: 'apiBuilder/summary',
        element: <Landingpage />,
      },
      {
        path: 'apiBuilder/uploadschema/:type',
        element: <UploadSchemaLogic />,
      },
      { path: 'entity-designer', element: <EntityDesigner /> },
      { path: 'entity-designer/entity-type/:entityType/uploadschema', element: <UploadSchema /> },
      {
        path: 'build-pipelines',
        element: <BuildPipelines />,
      },
      {
        path: 'release-pipelines',
        element: <ReleasePipelines />,
      },
      {
        path: 'functions/validations',
        element: <GenerateBusinessValidations />,
      },
      {
        path: 'functions/generate-crud',
        element: <GenerateCrud />,
      },
    ],
  },
];

export default componentConfig;
