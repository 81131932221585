import { RequestHandler } from 'libs/request-handler';
import {
  BuildPipeline,
  IComponentPipelineConfig,
} from 'app/models/pipeline/component-pipeline-config.model';
import { IPublicClientApplication } from '@azure/msal-browser';
import { ReleasePipeline } from 'app/main/application/types';
import { AxiosRequestConfig } from 'axios';

const apiServiceName = 'pipeline-service';

class ComponentPipelineConfigService extends RequestHandler {
  endpath = 'componentPipelineConfig';

  getBuildPipelines(instance: IPublicClientApplication, componentId: string) {
    return this.requestHandler(instance).get<IComponentPipelineConfig>(
      `/${this.endpath}/get/${componentId}`
    );
  }

  getComponentPipelineConfigByAppId(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IComponentPipelineConfig[]>(
      `/${this.endpath}/getComponentPipelineConfigByAppId/${appId}`,
      config
    );
  }

  getComponentPipelineConfig(
    instance: IPublicClientApplication,
    componentId: string,
    appId: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IComponentPipelineConfig>(
      `/${this.endpath}/get/${componentId}`,
      config
    );
  }

  updateBuildPipeline(
    instance: IPublicClientApplication,
    buildPipeline: BuildPipeline,
    componentId: string,
    appId: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(
      `/${this.endpath}/pipeline/build/update/${componentId}`,
      buildPipeline,
      config
    );
  }

  addBuildPipeline(
    instance: IPublicClientApplication,
    componentId: string,
    buildPipeline: BuildPipeline,
    appId: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(
      `/${this.endpath}/pipeline/build/add/${componentId}/${appId}`,
      buildPipeline,
      config
    );
  }

  deleteBuildPipeline(
    instance: IPublicClientApplication,
    pipelineName: string,
    appId: string,
    componentId: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(
      `/${this.endpath}/pipeline/build/delete/${componentId}/${pipelineName}`,
      {},
      config
    );
  }

  addReleasePipeline(
    instance: IPublicClientApplication,
    componentId: string,
    releasePipeline: ReleasePipeline,
    appId: string
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IComponentPipelineConfig[]>(
      `/${this.endpath}/pipeline/release/add/${componentId}/${appId}`,
      releasePipeline,
      config
    );
  }

  deleteReleasePipeline(
    instance: IPublicClientApplication,
    appId: string,
    componentId: string,
    releasePipeline: ReleasePipeline
  ) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IComponentPipelineConfig[]>(
      `/${this.endpath}/pipeline/release/delete/${componentId}`,
      releasePipeline,
      config
    );
  }
}

export default new ComponentPipelineConfigService(apiServiceName);
