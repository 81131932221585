import { ArrayDataField } from './ArrayDataField.model';
import { BooleanField } from './BooleanField.model';
import { FieldModel } from './FieldModel.model';
import { NumberDataField } from './NumberDataFields.model';
import { StringDataField } from './StringDataFields.model';

export class DataModel {
  isPrivate: boolean;
  dataModelId: string;
  accID: number;
  projID: number;
  accountName: string;
  projectName: string;
  dataModel: string;
  stringDataFieldList: StringDataField[];
  arrayDataFieldList: ArrayDataField[];
  numberDataFieldList: NumberDataField[];
  fieldDataModelList: FieldModel[];
  boolDataModelList: BooleanField[];

  constructor(
    isPrivate: boolean,
    dataModelId: string,
    accID: number,
    projID: number,
    accountName: string,
    projectName: string,
    dataModel: string,
    arrayDataFieldList: ArrayDataField[],
    numberDataFieldList: NumberDataField[],
    stringDataFieldList: StringDataField[],
    fieldDataModelList: FieldModel[],
    boolDataModelList: BooleanField[]
  ) {
    this.isPrivate = isPrivate;
    (this.dataModelId = dataModelId), (this.accID = accID);
    this.projID = projID;
    this.accountName = accountName;
    this.projectName = projectName;
    this.dataModel = dataModel;
    this.arrayDataFieldList = arrayDataFieldList;
    this.numberDataFieldList = numberDataFieldList;
    this.stringDataFieldList = stringDataFieldList;
    this.fieldDataModelList = fieldDataModelList;
    this.boolDataModelList = boolDataModelList;
  }
}
