import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import APIEditor from './components/APIEditor';
import BusinessUsecases from './components/BusinessUsecases';
import AccountAdmin from './components/AccountAdmin';
import ProjectAdministration from './components/ProjectAdministration';
import ApiDataModels from './components/ApiDataModels';
import ApiRequestParameters from './components/ApiRequestParameters';
import ApiSecuritySchemes from './components/ApiSecuritySchemes';
import DesignApiEndpoints from './components/DesignApiEndpoints';
import ApiCatalogCard from './components/ApiCatalogCard';
import APIEditorDesign from './components/APIEditorDesign';
// import DesignApiOnlineLoad from './components/DesignApiOnlineLoad';

const APIFactoryHome = lazy(() => import('./APIFactoryHome'));
const ExcelToOAS = lazy(() => import('./components/ExcelToOas'));
// const ExcelToRAML = lazy(() => import('./components/ExcelToRaml'));
const ReverseEngineering = lazy(() => import('./components/ReverseEngineering'));
const GenerateAPICode = lazy(() => import('./components/GenerateAPICode'));
const GenerateAPIDoc = lazy(() => import('./components/GenerateAPIDoc'));
// const RamlToOas = lazy(() => import('./components/RamlToOas'));
const YamlToJson = lazy(() => import('./components/YamlToJson'));
const WsdlToOas = lazy(() => import('./components/WsdlToOas'));
const ConvertOAS = lazy(() => import('./components/ConvertOAS2ToOAS3'));

const apifactoryHomeRoutes: RouteObject[] = [
  {
    path: '/apifactory',
    element: <APIFactoryHome />,
  },
  {
    path: '/apifactory/ExcelToOAS',
    element: <ExcelToOAS />,
  },
  // {
  //   path: '/apifactory/ExcelToRAML',
  //   element: <ExcelToRAML />,
  // },
  {
    path: '/apifactory/generateApiCode',
    element: <GenerateAPICode />,
  },
  {
    path: '/apifactory/generateApiDoc',
    element: <GenerateAPIDoc />,
  },
  {
    path: '/apifactory/reverseengineering',
    element: <ReverseEngineering />,
  },
  {
    path: '/apifactory/convertToOas',
    element: <WsdlToOas />,
  },
  {
    path: '/apifactory/convertoas2tooas3',
    element: <ConvertOAS />,
  },
  // {
  //   path: '/apifactory/ramlToOas',
  //   element: <RamlToOas />,
  // },
  {
    path: '/apifactory/yamlToJson',
    element: <YamlToJson />,
  },
  {
    path: '/apifactory/apiEditor',
    element: <APIEditor />,
  },
  {
    path: '/apifactory/apiEditor/:viewApiId',
    element: <APIEditor />,
  },
  {
    path: '/apifactory/apiEditorDesign/:viewApiId',
    element: <APIEditorDesign />,
  },
  {
    path: '/apifactory/apiEditorDesign',
    element: <APIEditorDesign />,
  },
  {
    path: '/apifactory/businessUsecases',
    element: <BusinessUsecases />,
  },
  {
    path: '/apifactory/account',
    element: <AccountAdmin />,
  },
  {
    path: '/apifactory/project',
    element: <ProjectAdministration />,
  },
  {
    path: '/apifactory/apiDataModels',
    element: <ApiDataModels />,
  },
  {
    path: '/apifactory/ApiRequestParameters',
    element: <ApiRequestParameters />,
  },
  {
    path: '/apifactory/ApiSecuritySchemes',
    element: <ApiSecuritySchemes />,
  },
  {
    path: '/apifactory/DesignApi',
    element: <DesignApiEndpoints />,
  },
  {
    path: '/apifactory/DesignApi/:editApiId',
    element: <DesignApiEndpoints />,
  },
  {
    path: '/apifactory/ApiCatalog',
    element: <ApiCatalogCard />,
  },
  // {
  //   path: '/UserProjectSelect',
  //   element: <DesignApiOnlineLoad />,
  // },
];

export default apifactoryHomeRoutes;
