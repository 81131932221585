import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NavigationItem } from 'app/models';
import { getAppChanges } from '../actions/navigation.actions';

const initialState = {
  navItems: [] as NavigationItem[],
  activeItem: '',
};

const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    setNavigation: (state, action: PayloadAction<NavigationItem[]>) => {
      state.navItems = action.payload;
    },
    resetNavigation: (state) => {
      state.navItems = [];
    },
    appendNavigationItem: (state, action: PayloadAction<NavigationItem>) => {
      state.navItems.push(action.payload);
    },
    prependNavigationItem: (state, action: PayloadAction<NavigationItem>) => {
      state.navItems.unshift(action.payload);
    },
    removeNavigationItem: (state, action: PayloadAction<string>) => {
      state.navItems = state.navItems.filter((item) => item.id !== action.payload);
    },
    setActiveNavItem: (state, action: PayloadAction<string>) => {
      state.activeItem = action.payload;
    },
    setFileChangesCount: (state, action: PayloadAction<number>) => {
      const badgeId = 'file-changes';
      const index = state.navItems.findIndex((item) => item.id === badgeId);
      state.navItems[index].badge = {
        loading: false,
        content: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppChanges.pending, (state, action) => {
        const index = state.navItems.findIndex((item) => item.id === action.meta.arg.badgeId);
        state.navItems[index].badge = {
          loading: true,
          content: '-',
        };
      })
      .addCase(getAppChanges.fulfilled, (state, action) => {
        const index = state.navItems.findIndex((item) => item.id === action.meta.arg.badgeId);
        state.navItems[index].badge = {
          loading: false,
          content: action.payload,
        };
      });
  },
});

export const {
  setNavigation,
  resetNavigation,
  appendNavigationItem,
  prependNavigationItem,
  removeNavigationItem,
  setFileChangesCount,
} = navigationSlice.actions;

export default navigationSlice.reducer;
