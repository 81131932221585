import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectWrapper = styled(Box)`
  body {
    height: 100%;
    background: #f6f7fd;
    font-size: 16px;
  }

  .snippet {
    position: relative;
    padding: 32px 5%;
    margin: 15% 0;
    border-radius: 16px;
  }

  .snippet > h2 {
    position: relative;
    font-size: 28px;
    margin: 0% 45%;
    font-family: sans-serif;
  }

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 32px 0;
    margin: 0 -5%;
    overflow: hidden;
  }
  .dot-pulse {
    position: relative;
    left: -9995px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #9880ff;
    color: #0d4c92;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }
  .dot-pulse::before,
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #9880ff;
    color: #0d4c92;
  }
  .dot-pulse::before {
    box-shadow: 9980px 0 0 0px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }

  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9980px 0 0 -5px;
    }
    30% {
      box-shadow: 9980px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 9980px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 10000px 0 0 -5px;
    }
    30% {
      box-shadow: 10000px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10000px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10020px 0 0 -5px;
    }
    30% {
      box-shadow: 10020px 0 0 2px;
    }
    60%,
    100% {
      box-shadow: 10020px 0 0 -5px;
    }
  }
`;
export default function RedirectHere() {
  const { search: queryParam } = useLocation();
  useEffect(() => {
    console.log('help');
    // console.log(queryParam);
    const popupSearchParams = new URLSearchParams(queryParam);
    const gitCode = popupSearchParams.get('code') ?? '';

    const actualState = localStorage.getItem('state');
    const currentState = popupSearchParams.get('state');

    if (actualState === currentState && gitCode) {
      localStorage.setItem('gitCode', window.btoa(gitCode));
    }
    window.close();
  });
  return (
    <RedirectWrapper>
      <div className="snippet">
        <h2>Redirecting</h2>
        <div className="stage">
          <div className="dot-pulse"></div>
        </div>
      </div>
    </RedirectWrapper>
  );
}
