import { SatelliteAlt } from '@mui/icons-material';
import { AlertColor, SnackbarOrigin } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MessageOptions = {
  anchorOrigin: SnackbarOrigin;
  message: string;
  autoHideDuration: number;
  variant: AlertColor;
};

interface InitialStateType {
  open: boolean;
  options: MessageOptions;
}

const initialState: InitialStateType = {
  open: false,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    autoHideDuration: 5000,
    message: 'Welcome to RapidX!',
    variant: 'success',
  },
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showSuccessMessage: (state, action) => {
      state.open = true;
      state.options.variant = 'success';
      state.options.message = action.payload;
    },
    showErrorMessage: (state, action) => {
      state.open = true;
      state.options.variant = 'error';
      state.options.message = action.payload;
    },
    showInfoMessage: (state, action) => {
      state.open = true;
      state.options.variant = 'info';
      state.options.message = action.payload;
    },
    showWarningMessage: (state, action) => {
      state.open = true;
      state.options.variant = 'warning';
      state.options.message = action.payload;
    },
    showCustomMessage: (state, action) => {
      state.open = true;
      state.options.variant = action.payload.variant;
      state.options.message = action.payload.message;
      state.options.autoHideDuration = action.payload.duration;
    },
    showMessage: (state, action: PayloadAction<Partial<MessageOptions>>) => {
      state.open = true;
      state.options = {
        ...initialState.options,
        ...action.payload,
      };
    },
    hideMessage: (state) => {
      state.open = false;
    },
  },
});

export const {
  hideMessage,
  showMessage,
  showErrorMessage,
  showSuccessMessage,
  showInfoMessage,
  showWarningMessage,
  showCustomMessage,
} = messageSlice.actions;

export default messageSlice.reducer;
