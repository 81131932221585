export enum PermissionEnum {
  // Tenant
  VIEW_TENANT = 'viewwtenant',
  UPDATE_TENANT = 'updatetenant',
  ADD_USER = 'createtenantuser',
  LIST_USER = 'listtenantuser',
  UPDATE_USER = 'updatetenantuser',
  DELETE_USER = 'deletetenantuser',
  VIEW_MRA = 'viewmra',
  ADD_MRA = 'addmra',
  UPDATE_MRA = 'updatemra',
  DELETE_MRA = 'deletemra',
  USER_REQUEST = 'userrequest',
  API_FACTORY_ADMIN = 'apiFactoryAdmin',

  // PROJ
  LIST_ALL_PROJECTS = 'listallprojects',
  LIST_PROJECTS = 'listprojects',
  VIEW_PROJECT = 'viewproject',
  UPDATE_PROJECTS = 'updateproject',
  DELETE_PROJECTS = 'deleteproject',
  VIEW_PROJECT_USERS = 'viewprojectusers',
  ASSIGN_PROJECT_USERS = 'assignprojectusers',
  REVOKE_PROJECT_USERS = 'revokeprojectusers',

  // APP
  ADD_APP = 'addapp',
  VIEW_APP = 'viewapp',
  VIEW_ALL_APPS = 'viewallapps',
  UPDATE_APP = 'updateapp',
  UPDATE_ALL_APPS = 'updateallapps',
  DELETE_APP = 'deleteapp',
  DELETE_ALL_APPS = 'deleteallapps',
  VIEW_APP_USERS = 'viewappusers',
  VIEW_ALL_APP_USERS = 'viewallappusers',
  ASSIGN_APP_USERS = 'assignappusers',
  ASSIGN_ALL_APP_USERS = 'assignallappusers',
  REVOKE_APP_USERS = 'revokeappusers',
  REVOKE_ALL_APP_USERS = 'revokeallappusers',
  // OPS = operations
  ADD_APP_OPS = 'addappops',
  ADD_ALL_APP_OPS = 'addallappops',
  VIEW_APP_OPS = 'viewappops',
  VIEW_ALL_APP_OPS = 'viewallappops',
  UPDATE_APP_OPS = 'updateappops',
  UPDATE_ALL_APP_OPS = 'updateallappops',
  DELETE_APP_OPS = 'deleteappops',
  DELETE_ALL_APP_OPS = 'deleteallappops',
}

export enum PermissionScope {
  NONE,
  TENANT,
  PROJECT,
  APPLICATION,
}

export enum PermissionType {
  GLOBAL = 'global',
  REGULAR = 'regular',
}
