import { createAsyncThunk } from '@reduxjs/toolkit';
import auditService from 'app/services/audit-management/audit.service';
import recentapplicationsService from 'app/services/app-designer/recent-applications.service';
import { IApplication } from 'app/models';

export const getAllAudits = createAsyncThunk(
  'dashboard/getAllAudits',
  async (data: WithInstance) => {
    const response = await auditService.getAllAudits(data.instance);
    return response.data;
  }
);

export const getAuditsById = createAsyncThunk(
  'dashboard/getAuditsById',
  async (data: WithInstance<{ page: number }>) => {
    const queryParams = new URLSearchParams({
      page: `${data.page}`,
    });
    const response = await auditService.getAuditsById(data.instance, queryParams);
    return response.data;
  }
);

export const getRecentApplications = createAsyncThunk(
  'dashboard/getRecentApplications',
  async (data: WithInstance) => {
    const response = await recentapplicationsService.getRecentApplications(data.instance);
    //return response.data.map((recentApp) => recentApp.application);
    return response.data.reduce<IApplication[]>((acc, { application }) => {
      if (application) acc.push(application);
      return acc;
    }, []);
  }
);
