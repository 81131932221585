import { IPublicClientApplication } from '@azure/msal-browser';
import { IMRA, Services } from 'app/models';
import { CheckNameAvailabilityDTO } from 'app/dtos';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'mra-management';

class MRAManagementService extends RequestHandler {
  endpath = 'mra';
  end = 'mraUsage';

  getAllPrivateMRAs(instance: IPublicClientApplication, tenantId: string) {
    return this.requestHandler(instance).get(`/${this.endpath}/getPrivateMRAs/${tenantId}`);
  }

  getMRA(instance: IPublicClientApplication, MRAId: string) {
    return this.requestHandler(instance).get<IMRA>(`/${this.endpath}/getById/${MRAId}`);
  }

  fetchFilteredMRAs(instance: IPublicClientApplication, queryParams: URLSearchParams) {
    return this.requestHandler(instance).get<IMRA[]>(
      `${this.endpath}/mra-gallery/fetch/filtered-results?${queryParams.toString()}`
    );
  }

  fetchPaginatedPrivateMRAs(instance: IPublicClientApplication, queryParams: URLSearchParams) {
    return this.requestHandler(instance).get<IMRA[]>(
      `${this.endpath}/private-mras/fetch/paginate?${queryParams.toString()}`
    );
  }

  getMraLogo(instance: IPublicClientApplication, mraId: string) {
    return this.requestHandler(instance).get<string>(`${this.endpath}/get-mra-logo/${mraId}`);
  }

  checkNameAvailability(instance: IPublicClientApplication, tenantId: string, MRAName: string) {
    return this.requestHandler(instance).get<CheckNameAvailabilityDTO>(
      `${this.endpath}/checkNameAvailability/${tenantId}/${MRAName}`
    );
  }

  createMRA(instance: IPublicClientApplication, payload: FormData) {
    return this.requestHandler(instance).post(`/${this.endpath}/uploadMRA`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updateMRA(instance: IPublicClientApplication, payload: FormData) {
    return this.requestHandler(instance).post(`/${this.endpath}/editMRA`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getAllSearchableTags(instance: IPublicClientApplication, tenantId: string) {
    return this.requestHandler(instance).get<string[]>(
      `${this.endpath}/mra-gallery/getSearchableTags/${tenantId}`
    );
  }

  deleteMRA(instance: IPublicClientApplication, MRAId: string) {
    return this.requestHandler(instance).delete(`${this.endpath}/delete/${MRAId}`);
  }

  getTotalEffortSavedByUser(userId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.end}/totalEffortSaved/${userId}`);
  }

  getMRACountByUser(userId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.end}/userMRACount/${userId}`);
  }

  createSubgenerator(instance: IPublicClientApplication, subgenerator: FormData, MRAId: string) {
    return this.requestHandler(instance).post(
      `/${this.endpath}/addSubgenerator/${MRAId}`,
      subgenerator,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
  }
}

export default new MRAManagementService(apiServiceName);
