import { IPublicClientApplication } from '@azure/msal-browser';
// import { IApplication, IChanges, Services, PrStatus } from 'app/models';
import {
  IApplication,
  IChanges,
  ICreatePrStatusResponse,
  IGetCreatePrResponse,
  Services,
  PrStatus,
} from 'app/models';
/*eslint import/no-cycle: [2, { maxDepth: 2 }]*/
import { RequestHandler } from 'libs/request-handler';
import { CreateBranchDTO, CreateApplicationDTO } from 'app/dtos';
import { AxiosRequestConfig } from 'axios';

const apiServiceName: Services = 'app-designer';

class ApplicationService extends RequestHandler {
  endpath = 'app';

  getApplication(instance: IPublicClientApplication, applicationId: string) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IApplication>(
      `/${this.endpath}/${applicationId}`,
      config
    );
  }

  createBranch(instance: IPublicClientApplication, appid: string, data: CreateBranchDTO) {
    const headers = { applicationid: appid };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(
      `/${this.endpath}/createBranch/${appid}`,
      data,
      config
    );
  }

  getRecentApplications(instance: IPublicClientApplication, userId: string) {
    return this.requestHandler(instance).get<IApplication[]>(
      `/${this.endpath}/get-recent-applications/${userId}`
    );
  }

  getApplicationsOfUser(instance: IPublicClientApplication, userId: string) {
    return this.requestHandler(instance).get<IApplication[]>(
      `/${this.endpath}/get-applications-of-user/${userId}`
    );
  }

  createApplication(instance: IPublicClientApplication, application: CreateApplicationDTO) {
    const headers = { projectid: application.projectId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IApplication>(
      `/${this.endpath}/create`,
      application,
      config
    );
  }

  deleteApplication(instance: IPublicClientApplication, applicationId: string) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).delete(`/${this.endpath}/${applicationId}`, config);
  }

  getGitCurrentChangesCount(instance: IPublicClientApplication, appid: string) {
    const headers = { applicationid: appid };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<number>(
      `/applicationusers/changesCount/${appid}`,
      config
    );
  }

  getGitCurrentChanges(instance: IPublicClientApplication, applicationId: string) {
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<IChanges[]>(
      `/applicationusers/changes/${applicationId}`,
      config
    );
  }

  getAppIdAndRolesByUserId(instance: IPublicClientApplication, userId: string) {
    return this.requestHandler(instance).get(`/${this.endpath}/permissions/UserInApps/${userId}`);
  }

  getUserRolesInApps(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<string[]>(`/${this.endpath}/UserRolesInApps`);
  }

  assignOrUpdateUserRoleToApplication(appId: any, data: any, instance: IPublicClientApplication) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(
      `/${this.endpath}/users/assign/${appId}`,
      data,
      config
    );
  }

  revokeUserRoleFromApplication(appId: string, userObj: any, instance: IPublicClientApplication) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(
      `/${this.endpath}/user/revoke/${appId}`,
      userObj,
      config
    );
  }
  async findUsers(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<any>(`/${this.endpath}/users/${appId}`, config);
  }

  updateAppDownloadFlag(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<{ message: string }>(
      `/${this.endpath}/updateAppDownloadFlag/${appId}`,
      config
    );
  }

  createTempAppWorkspace(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<{ message: string }>(
      `/${this.endpath}/createTempAppWorkspace/${appId}`,
      {},
      config
    );
  }

  createZip(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<{ message: string }>(
      `/${this.endpath}/createZip/${appId}`,
      config
    );
  }

  downloadCode(instance: IPublicClientApplication, appId: string) {
    const headers = { applicationid: appId };
    return this.requestHandler(instance).get<Blob>(`/${this.endpath}/downloadCode/${appId}`, {
      responseType: 'blob',
      headers,
    });
  }

  pushCode(instance: IPublicClientApplication, applicationId: string, commitMessage: string) {
    const data = {
      message: commitMessage,
      appId: applicationId,
    };
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IGetCreatePrResponse>(
      `/${this.endpath}/pushAppCode`,
      data,
      config
    );
  }
  pushCodeStatus(instance: IPublicClientApplication, appId: string) {
    // const data = {
    //   instanceId: instanceId,
    // };
    const headers = { applicationid: appId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<PrStatus>(
      `/${this.endpath}/pushCode/status/${appId}`,
      config
    );
  }
}

export default new ApplicationService(apiServiceName);
