import { combineReducers } from '@reduxjs/toolkit';
import applicationSlice from '../main/application/store/';
import layoutSlice from '../layouts/store';
import dashboardSlice from '../main/dashboard/store/';
import componentSlice from '../main/component/store';
import authSlice from '../main/auth/store';

const rootReducer = combineReducers({
  layoutSlice,
  authSlice,
  applicationSlice,
  dashboardSlice,
  componentSlice,
});

export default rootReducer;
