import { DialogContent, Paper, Typography } from '@mui/material';

function ViewApiDataModel({ data }: any) {
  const value = JSON.parse(data.ApiDataModel);
  const formattedData = JSON.stringify(value, null, 2); // Format JSON with 2 spaces indentation
  console.log(value);

  return (
    <DialogContent>
      <Paper
        elevation={3}
        style={{
          padding: '20px',
          maxWidth: '600px',
          margin: 'auto',
          whiteSpace: 'pre-wrap',
          overflowX: 'auto',
        }}
      >
        <Typography variant="body1" component="div">
          <div>
            <pre>{formattedData}</pre>
          </div>
        </Typography>
      </Paper>
    </DialogContent>
  );
}
export default ViewApiDataModel;
