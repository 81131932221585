import { createSlice } from '@reduxjs/toolkit';
import { IComponent, IEntity, IMRA } from 'app/models';
import { fetchComponent, getEntitiesData } from '../actions/component.actions';

type InitialComponentState = {
  component: IComponent;
  mra: IMRA;
  crudEntities: string[];
  generatedCrud: IEntity[];
  businessValidations?: string[];
  loading: boolean;
};

const initialState: InitialComponentState = {
  component: {} as IComponent,
  mra: {} as IMRA,
  crudEntities: [],
  generatedCrud: [],
  businessValidations: [],
  loading: false,
};

const componentSlice = createSlice({
  name: 'componentSlice',
  initialState,
  reducers: {
    clearComponentStore: (state) => {
      Object.assign(state, initialState);
    },
    addCrudEntity: (state, action) => {
      state.crudEntities.push(action.payload);
    },
    addBusinessValidation: (state, action) => {
      state.businessValidations?.push(action.payload);
    },
    updateComponentStatus: (state, action) => {
      state.component.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComponent.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchComponent.fulfilled, (state, action) => {
        state.loading = false;
        state.component = action.payload.component;
        state.mra = action.payload.mra;
        state.crudEntities = action.payload.component.entities;
        state.businessValidations = action.payload.component.businessValidations;
      });
    // .addCase(fetchComponentEntities.fulfilled, (state, action) => {
    //   state.entities = action.payload;
    // })
    builder.addCase(getEntitiesData.fulfilled, (state, action) => {
      state.generatedCrud = action.payload;
    });
  },
});

export const { clearComponentStore, addCrudEntity, addBusinessValidation, updateComponentStatus } =
  componentSlice.actions;

export default componentSlice.reducer;
