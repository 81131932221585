import { useState } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogContent,
  Typography,
} from '@mui/material';
import ApiService from 'app/services/fileupload.service';
import useDialog from '../hooks/popup';
import { useMsal } from '@azure/msal-react';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/layouts/store/slices/dialog.slice';

const AddAccountAdmin = ({ onCloseDialog }: { onCloseDialog: () => void }) => {
  // const [dialogOpen, setDialogOpen] = useState(true);
  const { instance } = useMsal();
  const [name, setAccountName] = useState('');
  const [description, setDescription] = useState('');
  const [vertical, setVertical] = useState('');
  const [geography, setGeography] = useState('');
  const [serviceLine, setServiceLine] = useState('');

  const [error, setError] = useState<string | null>(null);
  const { showDialog } = useDialog();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!name) {
      newErrors.name = 'Account Name is required';
    }

    if (!vertical) {
      newErrors.vertical = 'Select Vertical is required';
    }

    if (!geography) {
      newErrors.geography = 'Select Geography is required';
    }

    if (!serviceLine) {
      newErrors.serviceLine = 'Service Line is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (validateForm()) {
      const form = {
        name,
        description,
        vertical,
        geography,
        serviceLine,
      };

      ApiService.saveAccountDetails(instance, form)
        .then((response) => {
          const responseData = JSON.parse(response.data);
          if (responseData.code >= 200 && responseData.code < 210) {
            console.log('response', responseData.data);

            showDialog('Account Details Added successfully ');
            dispatch(showSuccessMessage('Account Details Added successfully '));
            dispatch(closeDialog());
            onCloseDialog();
          } else {
            // Non-2xx status code

            setError(`Request failed with status code ${responseData.message}`);
            showDialog(
              'Something went wrong , Please try after sometime',
              <p>{responseData.message}</p>
            );
            dispatch(showErrorMessage(<p>{responseData.message}</p>));
          }
        })
        .catch((errorInstance) => {
          console.error('Error:', errorInstance);

          let errorMessage: string;
          if (errorInstance.message.includes('Network Error')) {
            errorMessage = 'Something went wrong , Please try after sometime.';
          } else {
            errorMessage = `Request failed: ${errorInstance.message}`;
          }

          setError(errorInstance);
          showDialog('Error', <p>{errorMessage}</p>);
          dispatch(showErrorMessage(<p>{errorMessage}</p>));
        });
    }
  };

  return (
    <DialogContent>
      <form onSubmit={handleFormSubmit}>
        <Typography variant="h5">Add New Account Details</Typography>
        <div style={{ marginTop: '10px', marginLeft: 'auto' }}>
          <Button
            type="submit"
            onClick={handleFormSubmit}
            variant="contained"
            color="primary"
            style={{ marginLeft: '420px' }}
          >
            Create Account
          </Button>
        </div>

        <TextField
          label="Account Name"
          value={name}
          inputProps={{ maxLength: 20 }}
          onChange={(e) => setAccountName(e.target.value)}
          fullWidth
          required
          margin="normal"
          helperText={
            errors.name && (
              <Typography variant="caption" color="error">
                {errors.name}
              </Typography>
            )
          }
        />
        <TextField
          style={{ width: '100%', marginBottom: '20px' }}
          label="Description"
          inputProps={{ maxLength: 40 }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
        />

        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Select Vertical</InputLabel>
          <Select
            value={vertical}
            label="Select Vertical"
            onChange={(e) => setVertical(e.target.value)}
          >
            <MenuItem value="banking">Banking</MenuItem>
            <MenuItem value="fs">Financial Services</MenuItem>
            <MenuItem value="HI">Healthcare and Insurance</MenuItem>
            <MenuItem value="MC">Manufacturing and Consumer</MenuItem>
            <MenuItem value="HTPS">High Tech Professional Services</MenuItem>
            <MenuItem value="GTT">Global Travel and Transport</MenuItem>
          </Select>
          {errors.vertical && (
            <Typography variant="caption" color="error">
              {errors.vertical}
            </Typography>
          )}
        </FormControl>

        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Select Geography</InputLabel>
          <Select
            value={geography}
            label="Select Geography"
            onChange={(e) => setGeography(e.target.value)}
          >
            <MenuItem value="NorthAmerica">North America</MenuItem>
            <MenuItem value="eu">EU</MenuItem>
            <MenuItem value="apac">APAC</MenuItem>
            <MenuItem value="mena">MENA</MenuItem>
            <MenuItem value="emea">EMEA</MenuItem>
            <MenuItem value="NA">None</MenuItem>
          </Select>
          {errors.geography && (
            <Typography variant="caption" color="error">
              {errors.geography}
            </Typography>
          )}
        </FormControl>

        <FormControl style={{ width: '100%', marginBottom: '20px' }}>
          <InputLabel required>Service Line</InputLabel>
          <Select
            value={serviceLine}
            label="Service Line"
            onChange={(e) => setServiceLine(e.target.value)}
          >
            <MenuItem value="dct">DCT</MenuItem>
            <MenuItem value="dpe">DPE</MenuItem>
          </Select>
          {errors.serviceLine && (
            <Typography variant="caption" color="error">
              {errors.serviceLine}
            </Typography>
          )}
        </FormControl>
      </form>{' '}
    </DialogContent>
  );
};

export default AddAccountAdmin;
