import store from 'app/store';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import AppContext from './AppContext';
import AwSnap from './components/AwSnap';
import routes from './configs/routes.config';
import AuthProvider from './main/auth/AuthProvider';
import RapidXAuthentication from './RapidXAuthentication';

const GlobalRoutes = () => useRoutes(routes);

function App() {
  return (
    <ReduxProvider store={store}>
      <AppContext.Provider value={{ routes }}>
        <Router>
          <AuthProvider>
            <RapidXAuthentication>
              <ErrorBoundary key={window.location.pathname} FallbackComponent={AwSnap}>
                <GlobalRoutes />
              </ErrorBoundary>
            </RapidXAuthentication>
          </AuthProvider>
        </Router>
      </AppContext.Provider>
    </ReduxProvider>
  );
}

export default App;
