import { createAsyncThunk } from '@reduxjs/toolkit';
import applicationService from 'app/services/app-designer/application.service';

export const getApplicationsOfUser = createAsyncThunk(
  'dashboard/loadApplicationsForUser',
  async (data: WithInstance<{ userId: string }>) => {
    const response = await applicationService.getApplicationsOfUser(data.instance, data.userId);
    return response.data;
  }
);
