import { useEffect, useState } from 'react';
import { getHomeNavigation } from 'app/configs/navigation.config';
import BreadCrumb from 'app/components/BreadCrumb/BreadCrumb';
import useDialog from '../hooks/popup';
import Loading from 'app/components/Loading';
import { useMsal } from '@azure/msal-react';

import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ApiService from 'app/services/fileupload.service';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import { useDispatch } from 'app/store';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';

type DynamicTabs = { label: string; hasTextArea?: boolean }[];

const BusinessUsecases = () => {
  const { instance } = useMsal();
  const breadCrumbData = [
    {
      label: 'APIFactory ',
      url: `/apifactory`,
    },
    {
      label: 'ChatAPI - Business Use cases ',
      url: `/apifactory/businessUsecases`,
    },
  ];

  const [selectedUsecase, setSelectedUsecase] = useState('');
  const [domain, setDomain] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [spec, setSpec] = useState('');
  const [dynamicTabs, setDynamicTabs] = useState<DynamicTabs>([]);

  const [additionalField, setAdditionalField] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCopied, setIsCopied] = useState(false);

  const [codeSnippet, setCodeSnippet] = useState('');
  const [refineSpecContent, setrefineSpecContent] = useState('');
  const [draftSpecContent, setdraftSpecContent] = useState('');
  const [junitSpecContent, setjunitSpecContent] = useState('');

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const { showDialog } = useDialog();
  const [domainOptions, setDomainOptions] = useState<string[]>([]);
  const [subdomainOptions, setSubdomainOptions] = useState<string[]>([]);
  const [endpoints, setEndpoints] = useState<string[]>([]);
  const dispatch = useDispatch();
  const authorizedNavigation = useNavAuthorization();

  useEffect(() => {
    authorizedNavigation(getHomeNavigation());
  }, []);

  const handleUsecaseChange = (event: { target: { value: any } }) => {
    const selectedValue = event.target.value;
    setSelectedUsecase(selectedValue);

    if (selectedValue === 'Finance APIs') {
      console.log('Selected: Finance APIs');
      setDynamicTabs([
        { label: 'Create Domain specifications', hasTextArea: true },
        { label: 'Generate Specs', hasTextArea: true },
        { label: 'Create first draft of code', hasTextArea: true },
        { label: 'Generate Junit TCs', hasTextArea: true },
      ]);
      setDomainOptions(['Corporate Action']);
      setSubdomainOptions(['Voluntary Events', 'Optional Dividence', 'Mandatory Events']);
      setSelectedTab(0);
    } else if (selectedValue === 'Create Business APIs') {
      console.log('Selected: Create Business APIs');
      setDynamicTabs([
        { label: 'Create Domain specifications', hasTextArea: true },
        { label: 'Generate Specs', hasTextArea: true },
        { label: 'Create first draft of code', hasTextArea: true },
        { label: 'Generate Junit TCs', hasTextArea: true },
      ]);
      setDomainOptions(['Banking', 'Healthcare Insurance', 'Auto Insurance']);
      setSubdomainOptions([
        'Customer details',
        'Policy document generation',
        'Claim data',
        'Payment',
      ]);
      setSelectedTab(0);
    } else {
      setDynamicTabs([]);
      setDomain('');
      setSubdomain('');
      setEndpoint('');
      setSpec('');
      setDomainOptions([]);
      setSubdomainOptions([]);
      setSelectedTab(0);
    }
  };

  const handleDomainChange = (event: { target: { value: any } }) => {
    setDomain(event.target.value);
  };

  const handleSubdomainChange = (event: { target: { value: any } }) => {
    setSubdomain(event.target.value);
  };

  const handleEndpointChange = (event: { target: { value: any } }) => {
    // setEndpoints(event.target.value);
    setSelectedEndpoints(event.target.value);
  };
  const handleSpecChange = (event: { target: { value: any } }) => {
    setSpec(event.target.value);
  };
  const handleGenerate = () => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    let errorInstance: string;
    const domainValue = domain;
    const subdomainValue = subdomain;
    const fileformat = spec;
    const endPointNames = endpoints.map((item: string) => item.replace(/\n/g, '')).join(',');

    const prompt_string = `Subject: ${fileformat} Open API Specification creation for ${domainValue} - ${subdomainValue}
            Task: Create ${fileformat} API Specification with all applicable endpoints such as ${endPointNames}
            Instructions:
            The API Specification should contain all applicable endpoints with all methods and their request / response parameters and objects.
            Add securitySchemes in components sectiaon.
            Add Error responses for error codes 400, 401, 500, etc., for each endpoint and add respective schemas in component/schemas.
            Don't duplicate components.
            Path name should be unique.
            Each path in the API specification should have a unique URL.
            Mapping keys must be unique within the same level of indentation.
            Please keep it unique.
            Context: Imagine you are creating this API specification for ${domainValue} - ${subdomainValue}, targeting API developers.`;

    // Prepare data to send to the API
    const sendData = {
      apiId: 4,
      content: prompt_string,
    };

    // Call the API to generate the specification
    ApiService.generateSpecification(instance, sendData)
      .then((response) => {
        console.log('response', response);
        if (response.status >= 200 && response.status < 300) {
          setCodeSnippet(response.data);
        } else {
          setError(`Request failed with status code ${response.status}`);
          showDialog('Something went wrong , Please try after sometime', <p>{response.status}</p>);
        }
      })
      .catch((error) => {
        console.error('Error fetching Specification:', error);
        if (error && error.message) {
          errorInstance = error.message;

          let errorMessage;
          if (errorInstance.includes('Network Error')) {
            errorMessage = 'Something went wrong, please try again later.';
          } else {
            errorMessage = `Request failed: ${errorInstance}`;
          }

          setError(errorInstance);
          showDialog('Error', <p>{errorMessage}</p>);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after receiving the response
      });
  };

  const handleAdditionalField = (event: { target: { value: any } }) => {
    setAdditionalField(event.target.value);
  };
  const handleGenerateSpec = () => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);

    const yamlContent = codeSnippet;
    const additionalPrompt = additionalField;
    let errorInstance: string;
    const prompt_string = `Subject: YAML Open API Specification 
      Task : Modify YAML API Specification with ${additionalPrompt} and provide the complete YML only. 
      Follow below YAML: 
      ${yamlContent}`;

    const sendData = {
      apiId: 7,
      content: prompt_string,
    };
    ApiService.generateSpecification(instance, sendData)
      .then((response) => {
        console.log('response', response);
        if (response.status >= 200 && response.status < 300) {
          setrefineSpecContent(response.data);
        } else {
          setError(`Request failed with status code ${response.status}`);
          showDialog('Something went wrong , Please try after sometime', <p>{response.status}</p>);
        }
      })
      .catch((error) => {
        console.error('Error fetching refined Specifications:', error);
        if (error && error.message) {
          errorInstance = error.message;

          let errorMessage;
          if (errorInstance.includes('Network Error')) {
            errorMessage = 'Something went wrong, please try again later.';
          } else {
            errorMessage = `Request failed: ${errorInstance}`;
          }

          setError(errorInstance);
          showDialog('Error', <p>{errorMessage}</p>);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after receiving the response
      });
  };
  const handleGenerateCodedraft = () => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    let errorInstance: string;
    const yamlContent = refineSpecContent;
    const prompt_string = `Subject: Generate SpringBoot code for the API specification provided
        Task : 
        Create a Controller class with the necessary API endpoints
        Create a Service class to handle the business logic
        Create a DAO layer class to handle database operations
        Create a Model class to add respective fields
        Instructions :
        Add Method in controller for respective endpoints of API Specification and follow MVC pattern
        Don't give any explaination or extra text other than code.
        Follow below API Specification:
        ${yamlContent}`;

    const sendData = {
      apiId: 8,
      content: prompt_string,
    };

    ApiService.generateSpecification(instance, sendData)
      .then((response) => {
        console.log('response', response);
        if (response.status >= 200 && response.status < 300) {
          setdraftSpecContent(response.data);
        } else {
          setError(`Request failed with status code ${response.status}`);
          showDialog('Something went wrong , Please try after sometime', <p>{response.status}</p>);
        }
      })
      .catch((error) => {
        console.error('Error fetching code draft :', error);
        if (error && error.message) {
          errorInstance = error.message;

          let errorMessage;
          if (errorInstance.includes('Network Error')) {
            errorMessage = 'Something went wrong, please try again later.';
          } else {
            errorMessage = `Request failed: ${errorInstance}`;
          }

          setError(errorInstance);
          showDialog('Error', <p>{errorMessage}</p>);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after receiving the response
      });
  };
  const handlePushToRepo = () => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    const firstCodeDraft = draftSpecContent;
    let errorInstance: string;
    ApiService.saveToGithub(instance, firstCodeDraft)
      .then((response) => {
        const responseData = JSON.parse(response.data);
        if (responseData.code >= 200 && responseData.code < 210) {
          const outputLink = responseData.url;
          dispatch(showSuccessMessage('Code successfully uploaded to Git repository.'));
          showDialog(
            ' Please find the link below:',
            <a href={outputLink} target="_blank" rel="noopener noreferrer">
              Specification Link{' '}
            </a>
          );
        } else {
          setError(`Request failed with status code ${responseData.code}`);
          dispatch(showErrorMessage('Something went wrong , Please try after sometime'));
          showDialog(
            'Something went wrong , Please try after sometime',
            <p>{responseData.message}</p>
          );
        }
      })
      .catch((error) => {
        if (error && error.message) {
          errorInstance = error.message;

          let errorMessage;
          if (errorInstance.includes('Network Error')) {
            errorMessage = 'Something went wrong, please try again later.';
          } else {
            errorMessage = `Request failed: ${errorInstance}`;
          }

          setError(errorInstance);
          showDialog('Error', <p>{errorMessage}</p>);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGenerateJunit = () => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    const CodeContent = draftSpecContent;
    let errorInstance: string;
    const prompt_string = `Subject: Generate Junit code for the Spring Boot Code
        Task : 
        Create a Junit for Controller and 
        Service class with the respective methods
        Instructions :
        Don't give any explanation or extra text other than Junit code.
        Follow below is the spring boot code:
        ${CodeContent}`;

    const sendData = {
      apiId: 9,
      content: prompt_string,
    };

    ApiService.generateSpecification(instance, sendData)
      .then((response) => {
        console.log('response', response);
        if (response.status >= 200 && response.status < 300) {
          setjunitSpecContent(response.data);
        } else {
          setError(`Request failed with status code ${response.status}`);
          showDialog('Something went wrong , Please try after sometime', <p>{response.status}</p>);
        }
      })
      .catch((error) => {
        console.error('Error fetching Junit Code :', error);
        if (error && error.message) {
          errorInstance = error.message;

          let errorMessage;
          if (errorInstance.includes('Network Error')) {
            errorMessage = 'Something went wrong, please try again later.';
          } else {
            errorMessage = `Request failed: ${errorInstance}`;
          }

          setError(errorInstance);
          showDialog('Error', <p>{errorMessage}</p>);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after receiving the response
      });
  };
  const handleCopyClick = () => {
    const textarea = document.createElement('textarea');
    textarea.value = codeSnippet;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  useEffect(() => {
    if (selectedUsecase && domain && subdomain) {
      setLoading(true);
      const endpointString = `Subject: List of all applicable endpoint names for ${selectedUsecase} - ${domain} - ${subdomain}
        Task: Provide a list of applicable endpoint names for the ${selectedUsecase} - ${domain} - ${subdomain} API
        Instructions:
        Please generate a list of endpoint names for the ${selectedUsecase} - ${domain} - ${subdomain} API in the format below:
        {
           Endpoint1, Endpoint2, Endpoint3
        }
        Please ensure that the endpoint names are relevant to the business domain and subdomain, and there is no space.
        Example:
        {
          getClaimDetails, submitClaim, updateClaimStatus
        }
        Context:
        You are creating this endpoint list for ${selectedUsecase} - ${domain} - ${subdomain}, targeting API developers.`;

      // Prepare data to send to the API
      const sendData = {
        apiId: 5,
        content: endpointString,
      };

      // Call the API to fetch endpoints
      ApiService.getEndpoints(instance, sendData)
        .then((response) => {
          console.log('response', response.data);

          const endpointsArray = Object.values(response.data);
          const endpointsString = endpointsArray.join(', ');

          const splitEndpoints = endpointsString.split(', ');
          console.log('splitEndpoints', splitEndpoints);

          setEndpoints(splitEndpoints);
        })
        .catch((error) => {
          console.error('Error fetching endpoints:', error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after receiving the response
        });
    }
  }, [selectedUsecase, domain, subdomain]);

  return (
    <div className="max-w-3xl">
      <BreadCrumb breadCrumbData={breadCrumbData} />
      <br></br>
      <div className="flex flex-wrap">
        <form className="flex flex-row gap-9">
          <div className="flex flex-col">
            <InputLabel required htmlFor="dropdown">
              Select Usecase
            </InputLabel>
            <Select
              id="dropdown"
              label="Select Usecase"
              value={selectedUsecase}
              onChange={handleUsecaseChange}
            >
              <MenuItem value="Finance APIs">Finance APIs</MenuItem>
              <MenuItem value="Create Business APIs">Create Business APIs</MenuItem>
              <MenuItem value="Telecom Device APIs">Telecom Device APIs</MenuItem>
              <MenuItem value="Platform Migration">Platform Migration</MenuItem>
            </Select>
          </div>

          <div className="flex flex-col">
            <InputLabel required htmlFor="domain">
              Select Domain
            </InputLabel>
            <Select id="domain" label="Select Domain" value={domain} onChange={handleDomainChange}>
              {domainOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="flex flex-col">
            <InputLabel required htmlFor="subdomain">
              Select Subdomain
            </InputLabel>
            <Select
              id="subdomain"
              label="Select Subdomain"
              value={subdomain}
              onChange={handleSubdomainChange}
            >
              {subdomainOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <InputLabel required htmlFor="endpoints">
              Select Endpoint
            </InputLabel>
            <Select
              id="endpoints"
              label="Select Endpoint"
              multiple
              value={selectedEndpoints}
              onChange={handleEndpointChange}
              renderValue={(selected) => (selected as string[]).join(', ')}
            >
              {endpoints.map((endpointOption, index) => (
                <MenuItem key={index} value={endpointOption}>
                  <Checkbox
                    checked={selectedEndpoints.indexOf(endpointOption as never) > -1} // Type assertion for endpointOption
                  />
                  {endpointOption}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <InputLabel required htmlFor="fileformat">
              Select Specification
            </InputLabel>
            <Select
              id="fileformat"
              label="Select Specification"
              value={spec}
              onChange={handleSpecChange}
            >
              <MenuItem value="wsdl">WSDL</MenuItem>
              <MenuItem value="yaml">YAML</MenuItem>
              <MenuItem value="raml">RAML</MenuItem>
              <MenuItem value="sql">SQL</MenuItem>
            </Select>
          </div>
          <Button
            color="primary"
            onClick={handleGenerate}
            type="submit"
            variant="outlined"
            className="mt-4 w-[120px]"
            disabled={!selectedUsecase || !domain || !subdomain || !spec}
          >
            Generate
          </Button>
          <Loading loading={loading} />
        </form>
      </div>

      <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
        {dynamicTabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>

      <Box>
        {dynamicTabs.map((tab, index) => (
          <div key={index} hidden={selectedTab !== index}>
            {tab.label === 'Create Domain specifications' && tab.hasTextArea && (
              <TextField
                id="outlined-multiline-static"
                label="Code Snippet"
                multiline
                rows={14}
                variant="outlined"
                value={codeSnippet}
                // onChange={(e) => setCodeSnippet(e.target.value)}
                fullWidth
                className="mt-4"
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleCopyClick}>
                      <FileCopyIcon />
                    </IconButton>
                  ),
                }}
              />
            )}

            {tab.label === 'Generate Specs' && tab.hasTextArea && (
              <>
                <TextField
                  id="additionalField"
                  label="Additional Field"
                  variant="outlined"
                  value={additionalField}
                  onChange={handleAdditionalField}
                  className="mt-4"
                />
                <br></br>

                <Button
                  color="primary"
                  onClick={handleGenerateSpec}
                  type="submit"
                  variant="outlined"
                  className="mt-4 w-[120px]"
                >
                  Generate Spec
                </Button>

                <TextField
                  id="outlined-multiline-static"
                  label="Code Snippet"
                  multiline
                  rows={12}
                  value={refineSpecContent}
                  // onChange={(e) => setCodeSnippet(e.target.value)}
                  variant="outlined"
                  fullWidth
                  className="mt-4"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleCopyClick}>
                        <FileCopyIcon />
                      </IconButton>
                    ),
                  }}
                />
              </>
            )}

            {tab.label === 'Create first draft of code' && tab.hasTextArea && (
              <>
                <Button
                  color="primary"
                  onClick={handleGenerateCodedraft}
                  type="submit"
                  variant="outlined"
                  className="mt-4 w-[120px]"
                >
                  Generate Code Draft
                </Button>

                <TextField
                  id="outlined-multiline-static"
                  label="Code Snippet"
                  multiline
                  rows={12}
                  variant="outlined"
                  value={draftSpecContent}
                  // onChange={(e) => setCodeSnippet(e.target.value)}
                  fullWidth
                  className="mt-4"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleCopyClick}>
                        <FileCopyIcon />
                      </IconButton>
                    ),
                  }}
                />

                <Button
                  color="primary"
                  onClick={handlePushToRepo}
                  type="submit"
                  variant="outlined"
                  className="mt-4 w-[120px]"
                >
                  PUSH TO REPO
                </Button>
              </>
            )}

            {tab.label === 'Generate Junit TCs' && tab.hasTextArea && (
              <>
                <Button
                  color="primary"
                  onClick={handleGenerateJunit}
                  type="submit"
                  variant="outlined"
                  className="mt-4 w-[120px]"
                >
                  Generate Junit
                </Button>

                <TextField
                  id="outlined-multiline-static"
                  label="Code Snippet"
                  multiline
                  rows={12}
                  variant="outlined"
                  value={junitSpecContent}
                  // onChange={(e) => setCodeSnippet(e.target.value)}
                  fullWidth
                  className="mt-4"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleCopyClick}>
                        <FileCopyIcon />
                      </IconButton>
                    ),
                  }}
                />
              </>
            )}
          </div>
        ))}
      </Box>
    </div>
  );
};

export default BusinessUsecases;
