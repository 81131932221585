import { DialogContent } from '@mui/material';
import {
  Button,
  TextField,
  FormGroup,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
  Radio,
  RadioGroup,
  MenuItem,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiService from 'app/services/fileupload.service';
import { DataModel } from 'app/models/apiDataModels/dataModel.model';
import { AxiosError } from 'axios';
import { useDispatch } from 'app/store';
import { closeDialog } from 'app/layouts/store/slices/dialog.slice';
import { showSuccessMessage, showErrorMessage } from 'app/layouts/store/slices/message.slice';
import { ArrayDataField } from 'app/models/apiDataModels/ArrayDataField.model';
import { BooleanField } from 'app/models/apiDataModels/BooleanField.model';
import { FieldModel } from 'app/models/apiDataModels/FieldModel.model';
import { NumberDataField } from 'app/models/apiDataModels/NumberDataFields.model';
import { StringDataField } from 'app/models/apiDataModels/StringDataFields.model';
import { getOnlineNavigation } from 'app/configs/navigation.config';
import useNavAuthorization from 'app/hooks/Authorization/useNavAuthorization';
import { useMsal } from '@azure/msal-react';
import Loading from 'app/components/Loading';

class ProjectDetails {
  projectID: number;
  projectName: string;
  constructor(projectID: number, projectName: string) {
    this.projectID = projectID;
    this.projectName = projectName;
  }
}

function AddEditApiDataModel({ modelData, onCloseDialog }: any) {
  const { handleSubmit } = useForm<any>();
  const { instance } = useMsal();

  const [loading, setLoading] = useState(false);

  const [displayAccProjField, setDisplayAccProjField] = useState(false);
  const [displayDataModelName, setdisplayDataModelName] = useState(false);
  const [displayDataType, setDisplayDataType] = useState(false);
  const [displayDataFieldName, setDisplayDataFieldName] = useState(false);
  const [displayDataModel, setDisplayDataModel] = useState(false);

  const [fieldIsString, setFieldIsString] = useState(false);
  const [fieldIsArray, setFieldIsArray] = useState(false);
  const [fieldIsNumber, setFieldIsNumber] = useState(false);
  const [fieldIsBool, setFieldIsBool] = useState(false);
  const [isPrivateDataModel, setIsPrivateDataModel] = useState(false);
  const [isReadonly, setIsReadonly] = useState(false);

  const [dataModelName, setDataModelName] = useState('');
  const [repoType, setRepoType] = useState('');
  const [accountName, setAccountName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [dataType, setDataType] = useState('');
  const [fieldName, setFieldName] = useState('');

  //String
  const [stringFormat, setStringFormat] = useState('');
  const [stringFieldDescription, setStringFieldDescription] = useState('');
  const [minimumLength, setMinimumLength] = useState('');
  const [maximumLength, setMaximumLength] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [exampleValue, setExampleValue] = useState('');
  const [stringPattern, setStringPattern] = useState('');

  //Array
  const [arrayFieldDataModel, setArrayFieldDataModel] = useState<any>();
  const [arrayItems, setArrayItems] = useState('');
  const [arrayMinItems, setArrayMinItems] = useState('');
  const [arrayMaxItems, setArrayMaxItems] = useState('');

  //Number
  const [numberFieldDescription, setNumberFieldDescription] = useState('');
  const [numberMinValue, setNumberMinValue] = useState('');
  const [numberMaxValue, setNumberMaxValue] = useState('');
  const [exclusiveMinimum, setExclusiveMinimum] = useState('');
  const [exclusiveMaximum, setExclusiveMaximum] = useState('');
  const [numberMultipleOf, setNumberMultipleOf] = useState('');

  //Object
  const [dataModel, setDataModel] = useState('');

  const [boolId, setBoolId] = useState<any>();
  const [stringId, setStringId] = useState<any>();
  const [objId, setObjId] = useState<any>();
  const [arrayId, setArrayId] = useState<any>();
  const [numberId, setNumberId] = useState<any>();

  const [displayStringFieldDefinition, setDisplayStringFieldDefinition] = useState(false);
  const [displayNumberFieldDefinition, setDisplayNumberFieldDefinition] = useState(false);
  const [displayArrayFieldDefinition, setDisplayArrayFieldDefinition] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState<any>();
  const [dataModels, setDataModels] = useState<any>();
  const [projectDetailsList, setProjectDetailsList] = useState<any>([]);
  const [editTableRows, setEditTableRows] = useState<any>([]);

  const [numberDataFieldList, setNumberDataFieldList] = useState<NumberDataField[]>([]);
  const [arrayDataFieldList, setArrayDataFieldList] = useState<ArrayDataField[]>([]);
  const [stringDataFieldList, setStringDataFieldList] = useState<StringDataField[]>([]);
  const [fieldDataModelList, setFieldDataModelList] = useState<FieldModel[]>([]);
  const [boolDataModelList, setBoolDataModelList] = useState<BooleanField[]>([]);

  const [tempIndex, setTempIndex] = useState(undefined);

  function onChangeModelType(value: string) {
    console.log(' Data Model Type : ' + value);
    setdisplayDataModelName(true);
    if (value === 'private') {
      setRepoType('private');
      setIsPrivateDataModel(true);
      setDisplayAccProjField(true);
    } else {
      setRepoType('public');
      setIsPrivateDataModel(false);
      setDisplayAccProjField(false);
      setAccountName('');
      setProjectName('');
    }
  }

  function getProjectByAccountName(accountName: any) {
    console.log('Account Name : ' + accountName);
    setLoading(true);
    setAccountName(accountName);
    ApiService.getProjectDetails(instance)
      .then((response) => {
        console.log('Projects data', response);
        const responseData = response?.data?.data;
        // Filter projects based on account name
        const filteredProjects = responseData.filter(
          (i: { accountName: any }) => accountName === i.accountName
        );

        // Map filtered projects to ProjectDetails objects
        const mappedProjects = filteredProjects.map(
          (i: { projectId: number; projectName: string }) =>
            new ProjectDetails(i.projectId, i.projectName)
        );

        // Update the project list
        setProjectDetailsList(mappedProjects);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleDataField = () => {
    setDisplayDataType(true);
  };

  const handleDefineField = () => {
    if (fieldIsString) {
      setDisplayStringFieldDefinition(true);
      setFieldIsString(false);
    }
    if (fieldIsArray) {
      setDisplayArrayFieldDefinition(true);
      setFieldIsArray(false);
    }
    if (fieldIsNumber) {
      setDisplayNumberFieldDefinition(true);
      setFieldIsNumber(false);
    }
    if (fieldIsBool) {
      setFieldIsBool(false);
    }
  };

  const isNumeric = (value: any) => {
    return value !== undefined && value !== '' ? /^[0-9]*$/.test(value) : true;
  };

  const onClickOk = () => {
    console.log('onClickOk');
    if (dataType === 'Array') {
      if (!isNumeric(arrayMinItems) || !isNumeric(arrayMaxItems)) {
        return;
      }
    }

    if (dataType === 'String') {
      if (!isNumeric(minimumLength) || !isNumeric(maximumLength)) {
        return;
      }
    }

    if (dataType === 'Number') {
      if (
        !isNumeric(numberMinValue) ||
        !isNumeric(numberMaxValue) ||
        !isNumeric(numberMultipleOf)
      ) {
        return;
      }
    }
    if (tempIndex === undefined) {
      if (dataType && dataType !== '') {
        if (dataType === 'Array') {
          const isFieldNameAlreadyPresent = arrayDataFieldList.some(
            (item) => item.dataFieldName === fieldName
          );
          if (!isFieldNameAlreadyPresent) {
            const arrayRow = {
              dtype: dataType,
              fieldName: fieldName,
              arrayID: (Math.random() + 1).toString(36).substring(7),
              editarrayDataModel: arrayFieldDataModel,
              editarrayItems: arrayItems,
              editMinimumItems: arrayMinItems,
              editMaximumItems: arrayMaxItems,
            };
            setEditTableRows((prevRows: any) => [...prevRows, arrayRow]);

            const arrayField = new ArrayDataField(
              arrayRow.arrayID,
              arrayRow.fieldName,
              arrayRow.editarrayItems,
              arrayRow.editMinimumItems,
              arrayRow.editMaximumItems,
              arrayRow.editarrayDataModel
            );
            setArrayDataFieldList([...arrayDataFieldList, arrayField]);

            setFieldName('');
            setArrayItems('');
            setArrayMinItems('');
            setArrayMaxItems('');
            setDataType('');

            setDisplayArrayFieldDefinition(false);
            setDisplayDataType(false);
            setDisplayDataFieldName(false);
          }
        }
        if (dataType === 'Boolean') {
          const isFieldNameAlreadyPresent = boolDataModelList.some(
            (item) => item.DataFieldName === fieldName
          );
          if (!isFieldNameAlreadyPresent) {
            const booleanRow = {
              dtype: dataType,
              fieldName: fieldName,
              boolID: (Math.random() + 1).toString(36).substring(7),
            };
            setEditTableRows((prevRows: any) => [...prevRows, booleanRow]);

            const boolField = new BooleanField(booleanRow.boolID, booleanRow.fieldName);
            setBoolDataModelList([...boolDataModelList, boolField]);
            setFieldName('');
            setDataType('');

            setDisplayDataType(false);
            setDisplayDataFieldName(false);
          }
        }
        if (dataType === 'String') {
          const isFieldNameAlreadyPresent = stringDataFieldList.some(
            (item) => item.dataFieldName === fieldName
          );
          if (!isFieldNameAlreadyPresent) {
            const stringRow = {
              dtype: dataType,
              fieldName: fieldName,
              stringID: (Math.random() + 1).toString(36).substring(7),
              editstringFormat: stringFormat,
              editstringDescription: stringFieldDescription,
              editMinimumLength: minimumLength,
              editMaximumLength: maximumLength,
              editDefaultValue: defaultValue,
              editExampleValue: exampleValue,
              editStringPattern: stringPattern,
            };
            setEditTableRows((prevRows: any) => [...prevRows, stringRow]);

            const stringDataField = new StringDataField(
              stringRow.stringID,
              stringRow.fieldName,
              stringRow.editstringFormat,
              stringRow.editstringDescription,
              stringRow.editMinimumLength,
              stringRow.editMaximumLength,
              stringRow.editDefaultValue,
              stringRow.editExampleValue,
              stringRow.editStringPattern
            );
            setStringDataFieldList([...stringDataFieldList, stringDataField]);
            setFieldName('');
            setStringFormat('');
            setStringFieldDescription('');
            setMinimumLength('');
            setMaximumLength('');
            setDefaultValue('');
            setExampleValue('');
            setStringPattern('');
            setDataType('');

            setDisplayStringFieldDefinition(false);
            setDisplayDataType(false);
            setDisplayDataFieldName(false);
          }
        }

        if (
          dataType === 'Number' ||
          dataType === 'Integer' ||
          dataType === 'Double' ||
          dataType === 'Int32' ||
          dataType === 'Int64' ||
          dataType === 'Float'
        ) {
          const isFieldNameAlreadyPresent = numberDataFieldList.some(
            (item) => item.dataFieldName === fieldName
          );
          if (!isFieldNameAlreadyPresent) {
            const numberRow = {
              dtype: dataType,
              fieldName: fieldName,
              numberID: (Math.random() + 1).toString(36).substring(7),
              editNumberDescription: numberFieldDescription,
              editNumberMinValue: numberMinValue,
              editNumberMaxValue: numberMaxValue,
              editExMin: exclusiveMinimum,
              editExMax: exclusiveMaximum,
              editMultipleOf: numberMultipleOf,
            };
            setEditTableRows((prevRows: any) => [...prevRows, numberRow]);

            const numberDataField = new NumberDataField(
              numberRow.numberID,
              numberRow.dtype,
              numberRow.fieldName,
              numberRow.editNumberDescription,
              numberRow.editNumberMinValue,
              numberRow.editNumberMaxValue,
              numberRow.editExMin,
              numberRow.editExMax,
              numberRow.editMultipleOf
            );
            setNumberDataFieldList([...numberDataFieldList, numberDataField]);

            setFieldName('');
            setNumberFieldDescription('');
            setNumberMinValue('');
            setNumberMaxValue('');
            setExclusiveMinimum('');
            setExclusiveMaximum('');
            setNumberMultipleOf('');
            setDataType('');

            setDisplayNumberFieldDefinition(false);
            setDisplayDataType(false);
            setDisplayDataFieldName(false);
          }
        }
        if (dataType === 'Object') {
          const isFieldNameAlreadyPresent = fieldDataModelList.some(
            (item) => item.fieldDataModelName === fieldName
          );
          if (!isFieldNameAlreadyPresent) {
            const objectRow = {
              dtype: dataType,
              fieldName: dataModel,
              objectID: (Math.random() + 1).toString(36).substring(7),
            };
            setEditTableRows((prevRows: any) => [...prevRows, objectRow]);

            const dataModelName = dataModel;
            const dataModelObj = dataModels.find(
              (d: { DataModelName: string }) => d.DataModelName == dataModelName
            );
            const fieldDataModel = new FieldModel(
              objectRow.objectID,
              dataModelName,
              dataModelObj.DataModelID,
              ''
            );

            console.log(dataModelObj.DataModelID);
            setFieldDataModelList([...fieldDataModelList, fieldDataModel]);

            setDataModel('');
            setDataType('');

            setDisplayDataModel(false);
            setDisplayDataType(false);
            setDisplayDataFieldName(false);
          }
        }
      }
    } else {
      // Logic for pre-existing rows
      const arrayIdToCheck = arrayId;
      const objIdToCheck = objId;
      const boolIdToCheck = boolId;
      const stringIdToCheck = stringId;
      const numberIdToCheck = numberId;

      if (dataType === 'Array') {
        // const indexOfList = this.arrayDataFieldList.findIndex(item =>  item.dataFieldName === fieldNameToCheck);
        const IndexID = arrayDataFieldList.findIndex((item) => item.arrayId === arrayIdToCheck);

        const arrayRow = {
          dtype: dataType,
          fieldName: fieldName,
          arrayID: arrayDataFieldList[IndexID].arrayId,
          editarrayDataModel: arrayFieldDataModel,
          editarrayItems: arrayItems,
          editMinimumItems: arrayMinItems,
          editMaximumItems: arrayMaxItems,
        };

        editTableRows[tempIndex] = arrayRow;

        const arrayField = new ArrayDataField(
          arrayRow.arrayID,
          arrayRow.fieldName,
          arrayRow.editarrayItems,
          arrayRow.editMinimumItems,
          arrayRow.editMaximumItems,
          arrayRow.editarrayDataModel
        );

        arrayDataFieldList[IndexID] = arrayField;

        setFieldName('');
        setArrayItems('');
        setArrayMinItems('');
        setArrayMaxItems('');
        setDataType('');

        setDisplayArrayFieldDefinition(false);
        setDisplayDataType(false);
        setDisplayDataFieldName(false);
      }
      if (dataType === 'Boolean') {
        const IndexID = boolDataModelList.findIndex((item) => item.boolId === boolIdToCheck);

        const booleanRow = {
          dtype: dataType,
          fieldName: fieldName,
          boolID: boolDataModelList[IndexID].boolId,
        };

        editTableRows[tempIndex] = booleanRow;

        const boolField = new BooleanField(booleanRow.boolID, booleanRow.fieldName);

        boolDataModelList[IndexID] = boolField;

        setFieldName('');
        setDataType('');

        setDisplayDataType(false);
        setDisplayDataFieldName(false);
      }
      if (dataType === 'String') {
        const IndexID = stringDataFieldList.findIndex((item) => item.stringId === stringIdToCheck);

        const stringRow = {
          dtype: dataType,
          fieldName: fieldName,
          stringID: stringDataFieldList[IndexID].stringId,
          editstringFormat: stringFormat,
          editstringDescription: stringFieldDescription,
          editMinimumLength: minimumLength,
          editMaximumLength: maximumLength,
          editDefaultValue: defaultValue,
          editExampleValue: exampleValue,
          editStringPattern: stringPattern,
        };

        editTableRows[tempIndex] = stringRow;

        const stringDataField = new StringDataField(
          stringRow.stringID,
          stringRow.fieldName,
          stringRow.editstringFormat,
          stringRow.editstringDescription,
          stringRow.editMinimumLength,
          stringRow.editMaximumLength,
          stringRow.editDefaultValue,
          stringRow.editExampleValue,
          stringRow.editStringPattern
        );

        stringDataFieldList[IndexID] = stringDataField;

        setFieldName('');
        setStringFormat('');
        setStringFieldDescription('');
        setMinimumLength('');
        setMaximumLength('');
        setDefaultValue('');
        setExampleValue('');
        setStringPattern('');
        setDataType('');

        setDisplayStringFieldDefinition(false);
        setDisplayDataType(false);
        setDisplayDataFieldName(false);
      }
      if (
        dataType === 'Number' ||
        dataType === 'Integer' ||
        dataType === 'Double' ||
        dataType === 'Int32' ||
        dataType === 'Int64' ||
        dataType === 'Float'
      ) {
        const IndexID = numberDataFieldList.findIndex((item) => item.numberId === numberIdToCheck);

        const numberRow = {
          dtype: dataType,
          fieldName: fieldName,
          numberID: numberDataFieldList[IndexID].numberId,
          editNumberDescription: numberFieldDescription,
          editNumberMinValue: numberMinValue,
          editNumberMaxValue: numberMaxValue,
          editExMin: exclusiveMinimum,
          editExMax: exclusiveMaximum,
          editMultipleOf: numberMultipleOf,
        };

        editTableRows[tempIndex] = numberRow;

        const numberDataField = new NumberDataField(
          numberRow.numberID,
          numberRow.dtype,
          numberRow.fieldName,
          numberRow.editNumberDescription,
          numberRow.editNumberMinValue,
          numberRow.editNumberMaxValue,
          numberRow.editExMin,
          numberRow.editExMax,
          numberRow.editMultipleOf
        );

        numberDataFieldList[IndexID] = numberDataField;

        setFieldName('');
        setNumberFieldDescription('');
        setNumberMinValue('');
        setNumberMaxValue('');
        setExclusiveMinimum('');
        setExclusiveMaximum('');
        setNumberMultipleOf('');
        setDataType('');

        setDisplayNumberFieldDefinition(false);
        setDisplayDataType(false);
        setDisplayDataFieldName(false);
      }
      if (dataType === 'Object') {
        const IndexID = fieldDataModelList.findIndex((item) => item.objId === objIdToCheck);

        const objectRow = {
          dtype: dataType,
          fieldName: dataModel,
          objectID: fieldDataModelList[IndexID].objId,
        };

        editTableRows[tempIndex] = objectRow;

        const dataModelName = dataModel;
        const dataModelObj = dataModels.find(
          (d: { DataModelName: string }) => d.DataModelName == dataModelName
        );
        const fieldDataModel = new FieldModel(
          objectRow.objectID,
          dataModelName,
          dataModelObj.DataModelID,
          ''
        );

        console.log(dataModelObj.DataModelID);

        fieldDataModelList[tempIndex] = fieldDataModel;

        setDataModel('');
        setDataType('');

        setDisplayDataModel(false);
        setDisplayDataType(false);
        setDisplayDataFieldName(false);
      }
    }
    disableEditMode();
    setTempIndex(undefined);
  };

  function enableEditMode() {
    setIsReadonly(true);
  }

  function disableEditMode() {
    setIsReadonly(false);
  }

  const populateEdit = (rowData: any) => {
    console.log('populateEdit :', rowData);
    enableEditMode();
    setDisplayDataType(true);
    setDisplayDataFieldName(true);

    setDisplayDataModel(false);
    setDisplayNumberFieldDefinition(false);
    setDisplayArrayFieldDefinition(false);
    setDisplayStringFieldDefinition(false);

    const indexOfTable = editTableRows.indexOf(rowData);
    setTempIndex(indexOfTable);
    const selectedRow = editTableRows[indexOfTable];

    if (selectedRow.dtype == 'Boolean') {
      setDisplayDataFieldName(true);

      const indexOfList = boolDataModelList.findIndex(
        (item) => item.DataFieldName === selectedRow.fieldName
      );

      const boolDataField = boolDataModelList[indexOfList];

      setDataType(selectedRow.dtype);
      setFieldName(selectedRow.fieldName);

      setBoolId(boolDataField.boolId);
    }
    if (selectedRow.dtype === 'String') {
      setDisplayStringFieldDefinition(true);

      const indexOfList = stringDataFieldList.findIndex(
        (item) => item.dataFieldName === selectedRow.fieldName
      );

      const stringDataField = stringDataFieldList[indexOfList];

      setDataType(selectedRow.dtype);
      setFieldName(selectedRow.fieldName);
      setStringId(stringDataField.stringId);
      setStringFormat(stringDataField.stringFormat);
      setStringFieldDescription(stringDataField.stringDescription);
      setMinimumLength(stringDataField.MinimumLength);
      setMaximumLength(stringDataField.MaximumLength);
      setDefaultValue(stringDataField.DefaultValue);
      setExampleValue(stringDataField.ExampleValue);
      setStringPattern(stringDataField.StringPattern);
    }
    if (selectedRow.dtype === 'Object') {
      setDisplayDataModel(true);
      setDisplayDataFieldName(false);

      const indexOfList = fieldDataModelList.findIndex(
        (item) => item.fieldDataModelName === selectedRow.fieldName
      );
      const fieldDataModel = fieldDataModelList[indexOfList];

      setDataType(selectedRow.dtype);
      setDataModel(fieldDataModel.fieldDataModelName);
      setObjId(fieldDataModel.objId);
    }
    if (selectedRow.dtype == 'Array') {
      setDisplayArrayFieldDefinition(true);

      const indexOfList = arrayDataFieldList.findIndex(
        (item) => item.dataFieldName === selectedRow.fieldName
      );

      const arrayDataField = arrayDataFieldList[indexOfList];

      setDataType(selectedRow.dtype);
      setFieldName(selectedRow.fieldName);

      setArrayId(arrayDataField.arrayId);
      setArrayItems(arrayDataField.ArrayItems);
      setArrayFieldDataModel(arrayDataField.dataFieldId);
      setArrayMaxItems(arrayDataField.MaximumItems);
      setArrayMinItems(arrayDataField.MinimumItems);
    }
    if (
      selectedRow.dtype === 'Number' ||
      selectedRow.dtype === 'Integer' ||
      selectedRow.dtype === 'Int32' ||
      selectedRow.dtype === 'Int64' ||
      selectedRow.dtype === 'Float' ||
      selectedRow.dtype === 'Double'
    ) {
      setDisplayNumberFieldDefinition(true);

      const indexOfList = numberDataFieldList.findIndex(
        (item) => item.dataFieldName === selectedRow.fieldName
      );
      const numberDataField = numberDataFieldList[indexOfList];

      setDataType(selectedRow.dtype);
      setFieldName(selectedRow.fieldName);
      setNumberId(numberDataField.numberId);
      setNumberFieldDescription(numberDataField.numberDescription);
      setNumberMinValue(numberDataField.MinimumValue);
      setNumberMaxValue(numberDataField.MaximumValue);
      setExclusiveMaximum(numberDataField.ExclusiveMaximum);
      setExclusiveMinimum(numberDataField.ExclusiveMinimum);
      setNumberMultipleOf(numberDataField.MultipleOf);
    }
  };

  const deleteTableRows = (rowData: any) => {
    console.log('deleteTableRows :', rowData);
    const indexOfTable = editTableRows.indexOf(rowData);
    const selectedRow = editTableRows[indexOfTable];

    if (selectedRow.dtype === 'Boolean') {
      const updatedBoolList = boolDataModelList.filter(
        (boolDataField) => boolDataField.DataFieldName !== selectedRow.fieldName
      );
      setBoolDataModelList(updatedBoolList);
    }

    if (selectedRow.dtype === 'Array') {
      const updatedArrayList = arrayDataFieldList.filter(
        (arrayDataField) => arrayDataField.dataFieldName !== selectedRow.fieldName
      );
      setArrayDataFieldList(updatedArrayList);
    }

    if (
      selectedRow.dtype === 'Number' ||
      selectedRow.dtype === 'Integer' ||
      selectedRow.dtype === 'Int32' ||
      selectedRow.dtype === 'Int64' ||
      selectedRow.dtype === 'Float' ||
      selectedRow.dtype === 'Double'
    ) {
      const updatedNumberList = numberDataFieldList.filter(
        (numberDataField) => numberDataField.dataFieldName !== selectedRow.fieldName
      );
      setNumberDataFieldList(updatedNumberList);
    }

    if (selectedRow.dtype === 'Object') {
      const updatedFieldList = fieldDataModelList.filter(
        (fieldData) => fieldData.fieldDataModelName !== selectedRow.fieldName
      );
      setFieldDataModelList(updatedFieldList);
    }

    if (selectedRow.dtype === 'String') {
      const updatedStringList = stringDataFieldList.filter(
        (stringDataField) => stringDataField.dataFieldName !== selectedRow.fieldName
      );
      setStringDataFieldList(updatedStringList);
    }

    const updatedTableRows = editTableRows.filter((_: any, index: any) => index !== indexOfTable);
    setEditTableRows(updatedTableRows);
  };

  const handleModelChange = (e: any) => {
    console.log('Model Value : ' + e.target.value);
    setDataModelName(e.target.value);
  };

  const handleArrayFieldDataModelChange = (e: any) => {
    console.log('Array Model Value : ' + e.target.value);
    const selectedValue = e.target.value;
    if (typeof selectedValue === 'number') {
      setArrayFieldDataModel(selectedValue);
    } else {
      console.error('Selected value is not a number:', selectedValue);
    }
  };

  const isDefineFieldButtonDisabled = (): boolean => {
    if (fieldIsBool || dataType === 'Boolean') {
      return true;
    } else {
      return false;
    }
  };

  const displayDatafieldOnDataTypeChange = (e: any) => {
    console.log('Data Type Value : ' + e.target.value);
    const dataTypeValue = e.target.value;
    setDisplayStringFieldDefinition(false);
    setDisplayArrayFieldDefinition(false);
    setDisplayNumberFieldDefinition(false);
    if (dataTypeValue != 'Object') {
      setDisplayDataFieldName(true);
      setDisplayDataModel(false);
      if (dataTypeValue === 'String') {
        setFieldIsString(true);
      } else {
        setFieldIsString(false);
      }
      if (dataTypeValue === 'Array') {
        setFieldIsArray(true);
      } else {
        setFieldIsArray(false);
      }
      if (
        dataTypeValue === 'Number' ||
        dataTypeValue === 'Integer' ||
        dataTypeValue === 'Int32' ||
        dataTypeValue === 'Int64' ||
        dataTypeValue === 'Float' ||
        dataTypeValue === 'Double'
      ) {
        setFieldIsNumber(true);
      } else {
        setFieldIsNumber(false);
      }
      if (dataTypeValue === 'Boolean') {
        setFieldIsBool(true);
      } else {
        setFieldIsBool(false);
      }
    } else {
      setDisplayDataFieldName(false);
      setDisplayDataModel(true);
      setFieldIsString(false);
      setFieldIsNumber(false);
      setFieldIsArray(false);
      setFieldIsBool(false);
    }
  };

  const onSubmit: SubmitHandler<DataModel> = async (data: DataModel) => {
    console.log('onSubmit : ', data);
    dispatch(closeDialog());

    const projectId = sessionStorage.getItem('currentUserProjectID');

    const accObj = accounts.find((d: { name: string }) => d.name == accountName);
    const projObj = projectDetailsList.find(
      (d: { projectName: string }) => d.projectName == projectName
    );

    const dataModelParameters = new DataModel(
      isPrivateDataModel,
      modelData?.DataModelID ?? null,
      accObj?.id,
      projObj ? projObj.projectID : projectId,
      accObj?.name,
      projObj?.projectName,
      dataModelName,
      arrayDataFieldList,
      numberDataFieldList,
      stringDataFieldList,
      fieldDataModelList,
      boolDataModelList
    );
    console.log(dataModelParameters);
    console.log(JSON.stringify(dataModelParameters));
    try {
      setLoading(true);
      const response = await ApiService.saveDataModel(
        instance,
        JSON.stringify(dataModelParameters)
      );
      if (response.status === 200) {
        if (modelData?.DataModelID) {
          dispatch(showSuccessMessage('Data Model updated successfully'));
          onCloseDialog();
        } else {
          dispatch(showSuccessMessage('Data Model saved successfully'));
          onCloseDialog();
        }
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.log(error.message);
        dispatch(showErrorMessage('Unable to save the Data Model, please try after sometime.'));
      }
    } finally {
      setLoading(false);
    }
  };

  const authorizedNavigation = useNavAuthorization();

  useEffect(() => {
    authorizedNavigation(getOnlineNavigation());
    console.log(modelData);
    setLoading(true);
    ApiService.getAccountName(instance)
      .then((response) => {
        console.log('accounts data', response);
        setAccounts(response?.data?.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(true);

    const sessionProjectId = sessionStorage.getItem('currentUserProjectID');
    ApiService.getDataModelbyProjectId(instance, sessionProjectId)
      .then((response) => {
        console.log('models data', response);
        setDataModels(response?.data?.data);
      })
      .catch((errorInstance) => {
        console.error('Error:', errorInstance);
      })
      .finally(() => {
        setLoading(false);
      });

    //edit form
    if (modelData !== undefined) {
      setLoading(true);
      const dataModelId = modelData.DataModelID;
      ApiService.getDataModelById(instance, dataModelId)
        .then((response) => {
          console.log('data Model By Id : ', response?.data?.data);
          const dataModelObject = response?.data?.data;
          for (const modeldata of dataModelObject) {
            const apiDataModelObj = modeldata.ApiDataModel;
            const jsonData = JSON.parse(apiDataModelObj);
            setIsPrivateDataModel(jsonData.isPrivate);

            if (jsonData.isPrivate) {
              setRepoType('private');
              setDisplayAccProjField(true);
              setdisplayDataModelName(true);
            } else {
              setRepoType('public');
              setdisplayDataModelName(true);
            }
            //for data model name field
            setDataModelName(modeldata?.DataModelName);
            //for account name dropdown
            setAccountName(jsonData?.accountName);
            //for project name dropdown
            getProjectByAccountName(jsonData?.accountName);
            setProjectName(jsonData?.projectName);

            //Fetch json data values in table on page edit
            setEditTableRows([]);
            if (jsonData.stringDataFieldList != null) {
              for (const stringField of jsonData.stringDataFieldList) {
                const newRow = {
                  dtype: 'String',
                  fieldName: stringField.dataFieldName,
                };
                setEditTableRows((prevRows: any) => [...prevRows, newRow]);
              }
            }
            if (jsonData.boolDataModelList != null) {
              for (const boolField of jsonData.boolDataModelList) {
                const newRow = {
                  dtype: 'Boolean',
                  fieldName: boolField.DataFieldName,
                };
                setEditTableRows((prevRows: any) => [...prevRows, newRow]);
              }
            }

            if (jsonData.arrayDataFieldList != null) {
              for (const arrayField of jsonData.arrayDataFieldList) {
                const newRow = {
                  dtype: 'Array',
                  fieldName: arrayField.dataFieldName,
                };
                setEditTableRows((prevRows: any) => [...prevRows, newRow]);
              }
            }

            if (jsonData.numberDataFieldList != null) {
              for (const numberField of jsonData.numberDataFieldList) {
                const newRow = {
                  dtype: numberField.numberDataType,
                  fieldName: numberField.dataFieldName,
                };
                setEditTableRows((prevRows: any) => [...prevRows, newRow]);
              }
            }

            if (jsonData.fieldDataModelList != null) {
              for (const objField of jsonData.fieldDataModelList) {
                const newRow = {
                  dtype: 'Object',
                  fieldName: objField.fieldDataModelName,
                };
                setEditTableRows((prevRows: any) => [...prevRows, newRow]);
              }
            }

            setFieldDataModelList(jsonData.fieldDataModelList);
            setArrayDataFieldList(jsonData.arrayDataFieldList);
            setNumberDataFieldList(jsonData.numberDataFieldList);
            setStringDataFieldList(jsonData.stringDataFieldList);
            setBoolDataModelList(jsonData.boolDataModelList);
          }
        })
        .catch((errorInstance) => {
          console.error('Error:', errorInstance);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <DialogContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Loading loading={loading} />
        <FormGroup className="flex flex-col justify-center">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel id="repoType" style={{ marginRight: '10px', marginLeft: '10px' }}>
              Data Model Type:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="select-repo-type"
              onChange={(e) => {
                onChangeModelType(e.target.value);
              }}
              value={repoType}
              name="radio-buttons-group"
            >
              <FormControlLabel value="private" control={<Radio size="small" />} label="Private" />
              <FormControlLabel value="public" control={<Radio size="small" />} label="Public" />
            </RadioGroup>
          </div>
          {displayAccProjField && (
            <div className="flex flex-col gap-5 p-2 justify-center">
              <FormControl fullWidth size="small">
                <InputLabel id="location-label" required>Select Account Name</InputLabel>
                <Select
                  labelId="accountName"
                  label="Select Account Name"
                  placeholder={'Select Account Name'}
                  size="small"
                  variant="outlined"
                  value={accountName}
                  required
                  onChange={(e) => getProjectByAccountName(e.target.value)}
                >
                  {accounts?.map((account: any, index: number) => {
                    return (
                      <MenuItem value={account.name} key={index}>
                        {account.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel id="location-label" required>Select Project Name</InputLabel>
                <Select
                  labelId="projectName"
                  label="Select Project Name"
                  placeholder={'Select Project Name'}
                  size="small"
                  variant="outlined"
                  value={projectName}
                  required
                  onChange={(e) => setProjectName(e.target.value)}
                >
                  {projectDetailsList.map((project: any, index: number) => {
                    return (
                      <MenuItem value={project.projectName} key={index}>
                        {project.projectName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          {displayDataModelName && (
            <div className="flex flex-col gap-5 p-2 justify-center">
              <TextField
                id="data-model-name"
                label="Data Model Name"
                variant="outlined"
                inputProps={{ maxLength: 30 }}
                onChange={handleModelChange}
                required
                size="small"
                value={dataModelName}
              />
              <Button
                variant="contained"
                color="primary"
                type="button"
                size="small"
                disabled={!dataModelName.trim()}
                onClick={handleDataField}
              >
                Add Data Field
              </Button>
              {displayDataModel && (
                <FormControl fullWidth size="small">
                  <InputLabel id="location-label">Data Model</InputLabel>
                  <Select
                    labelId="dataModel"
                    label="dataModel"
                    placeholder={'Data Model'}
                    size="small"
                    variant="outlined"
                    onChange={(e) => setDataModel(e.target.value)}
                    value={dataModel}
                  >
                    {dataModels.map((dataModel: any, index: number) => {
                      return (
                        <MenuItem value={dataModel.DataModelName} key={index}>
                          {dataModel.DataModelName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {displayDataType && (
                <div className="flex flex-col gap-5 justify-center">
                  <FormControl fullWidth size="small">
                    <InputLabel id="location-label">Data Type</InputLabel>
                    <Select
                      labelId="dataType"
                      label="dataType"
                      placeholder={'Data Type'}
                      size="small"
                      variant="outlined"
                      onChange={(e) => {
                        setDataType(e.target.value);
                        displayDatafieldOnDataTypeChange(e);
                      }}
                      value={dataType}
                      disabled={isReadonly}
                    >
                      <MenuItem value="String">String</MenuItem>
                      <MenuItem value="Boolean">Boolean</MenuItem>
                      <MenuItem value="Array">Array</MenuItem>
                      <MenuItem value="Object">Object</MenuItem>
                      <MenuItem value="Integer">Integer</MenuItem>
                      <MenuItem value="Number">Number</MenuItem>
                      <MenuItem value="Double">Double</MenuItem>
                      <MenuItem value="Float">Float</MenuItem>
                      <MenuItem value="Int32">Int 32</MenuItem>
                      <MenuItem value="Int64">Int 64</MenuItem>
                    </Select>
                  </FormControl>

                  {displayDataFieldName && (
                    <TextField
                      id="data-field-name"
                      label="Data Field Name"
                      variant="outlined"
                      inputProps={{ maxLength: 30 }}
                      required
                      size="small"
                      onChange={(e) => setFieldName(e.target.value)}
                      value={fieldName}
                    />
                  )}

                  {displayStringFieldDefinition && (
                    <div id="stringFieldDefinition" className="flex flex-col gap-5 justify-center">
                      <FormControl fullWidth size="small">
                        <InputLabel id="location-label">String Format</InputLabel>
                        <Select
                          labelId="stringFormat"
                          label="stringFormat"
                          placeholder={'string Format'}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setStringFormat(e.target.value)}
                          value={stringFormat}
                        >
                          <MenuItem value="email">email</MenuItem>
                          <MenuItem value="binary">binary</MenuItem>
                          <MenuItem value="byte">byte</MenuItem>
                          <MenuItem value="password">password</MenuItem>
                          <MenuItem value="date-time">date-time</MenuItem>
                          <MenuItem value="date">date</MenuItem>
                          <MenuItem value="uuid">uuid</MenuItem>
                          <MenuItem value="uri">uri</MenuItem>
                          <MenuItem value="hostname">hostname</MenuItem>
                          <MenuItem value="ipv4">ipv4</MenuItem>
                          <MenuItem value="ipv6">ipv6</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        id="string-description"
                        label="String Description"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setStringFieldDescription(e.target.value)}
                        value={stringFieldDescription}
                      />
                      <TextField
                        id="minimum-length"
                        label="Minimum Length"
                        variant="outlined"
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 5 }}
                        error={!isNumeric(minimumLength)}
                        helperText={
                          !isNumeric(minimumLength) ? 'Please enter only numeric values.' : ''
                        }
                        onChange={(e) => setMinimumLength(e.target.value)}
                        value={minimumLength}
                      />
                      <TextField
                        id="maximum-length"
                        label="Maximum Length"
                        variant="outlined"
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 5 }}
                        error={!isNumeric(maximumLength)}
                        helperText={
                          !isNumeric(maximumLength) ? 'Please enter only numeric values.' : ''
                        }
                        onChange={(e) => setMaximumLength(e.target.value)}
                        value={maximumLength}
                      />
                      <TextField
                        id="default-value"
                        label="Default Value"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setDefaultValue(e.target.value)}
                        value={defaultValue}
                      />
                      <TextField
                        id="example-value"
                        label="Example Value"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setExampleValue(e.target.value)}
                        value={exampleValue}
                      />
                      <TextField
                        id="string-pattern"
                        label="String Pattern"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setStringPattern(e.target.value)}
                        value={stringPattern}
                      />
                    </div>
                  )}
                  {displayArrayFieldDefinition && (
                    <div id="arrayFieldDefinition" className="flex flex-col gap-5 justify-center">
                      <FormControl fullWidth size="small">
                        <InputLabel id="location-label">Array Items</InputLabel>
                        <Select
                          labelId="arrayItems"
                          label="arrayItems"
                          placeholder={'Array Items'}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setArrayItems(e.target.value)}
                          value={arrayItems}
                        >
                          <MenuItem value="string">String</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                          <MenuItem value="Integer">Integer</MenuItem>
                          <MenuItem value="Object">Object</MenuItem>
                          <MenuItem value="Boolean">Boolean</MenuItem>
                          <MenuItem value="Int32">Int 32</MenuItem>
                          <MenuItem value="Int64">Int 64</MenuItem>
                          <MenuItem value="Float">Float</MenuItem>
                          <MenuItem value="Double">Double</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth size="small">
                        <InputLabel id="location-label">Data Model</InputLabel>
                        <Select
                          labelId="arrayFieldDataModel"
                          label="Data Model"
                          placeholder={'Data Model'}
                          size="small"
                          variant="outlined"
                          onChange={handleArrayFieldDataModelChange}
                          value={arrayFieldDataModel}
                        >
                          {dataModels.map((dataModel: any, index: number) => {
                            return (
                              <MenuItem value={dataModel.DataModelID} key={index}>
                                {dataModel.DataModelName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <TextField
                        id="array-min-items"
                        label="Minimum Items"
                        variant="outlined"
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 5 }}
                        error={!isNumeric(arrayMinItems)}
                        helperText={
                          !isNumeric(arrayMinItems) ? 'Please enter only numeric values.' : ''
                        }
                        onChange={(e) => setArrayMinItems(e.target.value)}
                        value={arrayMinItems}
                      />
                      <TextField
                        id="array-max-items"
                        label="Maximum Items"
                        variant="outlined"
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 5 }}
                        error={!isNumeric(arrayMaxItems)}
                        helperText={
                          !isNumeric(arrayMaxItems) ? 'Please enter only numeric values.' : ''
                        }
                        onChange={(e) => setArrayMaxItems(e.target.value)}
                        value={arrayMaxItems}
                      />
                    </div>
                  )}
                  {displayNumberFieldDefinition && (
                    <div id="numberFieldDefinition" className="flex flex-col gap-5 justify-center">
                      <TextField
                        id="number-field-description"
                        label="Number Description"
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setNumberFieldDescription(e.target.value)}
                        value={numberFieldDescription}
                      />
                      <TextField
                        id="number-min-value"
                        label="Minimum Value"
                        variant="outlined"
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 5 }}
                        error={!isNumeric(numberMinValue)}
                        helperText={
                          !isNumeric(numberMinValue) ? 'Please enter only numeric values.' : ''
                        }
                        onChange={(e) => setNumberMinValue(e.target.value)}
                        value={numberMinValue}
                      />
                      <TextField
                        id="number-max-value"
                        label="Maximum Value"
                        variant="outlined"
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 5 }}
                        error={!isNumeric(numberMaxValue)}
                        helperText={
                          !isNumeric(numberMaxValue) ? 'Please enter only numeric values.' : ''
                        }
                        onChange={(e) => setNumberMaxValue(e.target.value)}
                        value={numberMaxValue}
                      />
                      <FormControl fullWidth size="small">
                        <InputLabel id="location-label">Exclusive Minimum</InputLabel>
                        <Select
                          labelId="exclusiveMinimum"
                          label="exclusiveMinimum"
                          placeholder={'Exclusive Minimum'}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setExclusiveMinimum(e.target.value)}
                          value={exclusiveMinimum}
                        >
                          <MenuItem value="yes">YES</MenuItem>
                          <MenuItem value="no">NO</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth size="small">
                        <InputLabel id="location-label">Exclusive Maximum</InputLabel>
                        <Select
                          labelId="exclusiveMaximum"
                          label="exclusiveMaximum"
                          placeholder={'Exclusive Maximum'}
                          size="small"
                          variant="outlined"
                          onChange={(e) => setExclusiveMaximum(e.target.value)}
                          value={exclusiveMaximum}
                        >
                          <MenuItem value="yes">YES</MenuItem>
                          <MenuItem value="no">NO</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        id="number-multiple-of"
                        label="Multiple Of"
                        variant="outlined"
                        size="small"
                        type="text"
                        inputProps={{ maxLength: 5 }}
                        error={!isNumeric(numberMultipleOf)}
                        helperText={
                          !isNumeric(numberMultipleOf) ? 'Please enter only numeric values.' : ''
                        }
                        onChange={(e) => setNumberMultipleOf(e.target.value)}
                        value={numberMultipleOf}
                      />
                    </div>
                  )}

                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    onClick={handleDefineField}
                    disabled={isDefineFieldButtonDisabled()}
                  >
                    Define Field
                  </Button>
                  <Button variant="contained" size="small" type="button" onClick={onClickOk}>
                    OK
                  </Button>
                </div>
              )}
              {editTableRows.length > 0 && (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data Type</TableCell>
                        <TableCell>Field/Object Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(editTableRows || []).map((field: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{field.dtype}</TableCell>
                          <TableCell>{field.fieldName}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => populateEdit(field)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteTableRows(field)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
              <Button variant="contained" size="small" type="submit">
                {modelData === undefined ? 'Add' : 'Update'}
              </Button>
            </div>
          )}
        </FormGroup>
      </form>
    </DialogContent>
  );
}

export default AddEditApiDataModel;
