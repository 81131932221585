export class FieldModel {
  objId: string;
  fieldDataModelName: string;
  fieldDataModelId: number;
  fieldDataModelversion: string;

  constructor(
    objId: string,
    fieldDataModelName: string,
    fieldDataModelId: number,
    fieldDataModelversion: string
  ) {
    //constructor(fieldDataModelName: string, fieldDataModelId: number, fieldDataModelversion: string){
    this.objId = objId;
    this.fieldDataModelName = fieldDataModelName;
    this.fieldDataModelId = fieldDataModelId;
    this.fieldDataModelversion = fieldDataModelversion;
  }
}
