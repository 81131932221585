import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IApplication,
  IComponent,
  IChanges,
  IApplicationUsers,
  ICreateApp,
  IComponentDetails,
  IPrRequest,
  ICreatePrStatusResponse,
  IComponentVariations,
  PrStatus,
  IComponentStatus,
  IComponentStatusByAppId,
} from 'app/models';
import {
  IComponentPipelineConfig,
  ReleasePipeline,
  DeploymentDetail,
} from 'app/models/pipeline/component-pipeline-config.model';
import {
  checkIfUserIsAuthorized,
  fetchApplicationComponents,
  fetchChanges,
  fetchApplication,
  getApplicationComponents,
  getApplicationPipelineConfig,
  getComponentPipelineConfigByAppId,
  getApplicationUsers,
  generateAccessToken,
  // createPullRequest,
  // getPRstatus,
  // checkPRStatus,
  pushAppCode,
  pushAppCodeStatus,
  getAppCommitLogs,
} from '../actions/application.actions';
import { IApplicationPipelineConfig } from 'app/models/pipeline/application-pipeline-config.model';
import { ICommitLogs } from 'app/models/app-designer/appcommitlogs.model';

type InitialApplicationState = {
  prStatus: ICreatePrStatusResponse;
  updatedPrStatus: PrStatus;
  application: IApplication;
  instanceId: string;
  appId: string;
  components: IComponent[];
  changes: IChanges[];
  componentPipelineConfig: IComponentPipelineConfig[];
  applicationPipelineConfig: IApplicationPipelineConfig;
  releasePipelines: ReleasePipeline[];
  deploymentDetails: DeploymentDetail;
  pendingAppPipelineConfigloading: true;
  changesCount: number;
  commitMessage: string;
  loading: boolean;
  logsLoading: boolean;
  isUserAuthorized: boolean;
  appUsers: any;
  createAppData: ICreateApp;
  isCustomizeMRAEnabled: boolean;
  isCompStatusCheckTimerRunning: boolean;
  appCommitLogs: ICommitLogs[];
};

const initialState: InitialApplicationState = {
  instanceId: '',
  appId: '',
  prStatus: {} as ICreatePrStatusResponse,
  updatedPrStatus: {} as PrStatus,
  application: {} as IApplication,
  components: [],
  changes: [],
  componentPipelineConfig: [],
  applicationPipelineConfig: {} as IApplicationPipelineConfig,
  releasePipelines: [],
  deploymentDetails: {} as DeploymentDetail,
  pendingAppPipelineConfigloading: true,
  changesCount: 0,
  commitMessage: '',
  loading: true,
  logsLoading: true,
  isUserAuthorized: false,
  appUsers: {},
  createAppData: {
    applicationDetails: {},
    projectDetails: {},
    componentDetails: { mraId: '', mraName: '', componentVariations: [], mraVariations: [] },
    versionControl: { token: '', name: '' },
    loading: false,
    appDetailsValid: false,
  },
  isCustomizeMRAEnabled: false,
  isCompStatusCheckTimerRunning: false,
  appCommitLogs: [],
};

const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    setApplication: (state, action: PayloadAction<IApplication>) => {
      state.application = action.payload;
    },
    setPullReqResponse: (state, action: PayloadAction<string>) => {
      state.instanceId = action.payload;
    },
    setPrStatus: (state, action: PayloadAction<ICreatePrStatusResponse>) => {
      state.prStatus = action.payload;
    },
    setUpdatedPrStatus: (state, action: PayloadAction<PrStatus>) => {
      state.updatedPrStatus = action.payload;
    },
    clearApplication: (state) => {
      state.application = {} as IApplication;
    },
    deleteComponent: (state, action: PayloadAction<string>) => {
      state.components = state.components.filter((component) => component._id !== action.payload);
    },
    clearComponents: (state) => {
      state.components = [];
    },
    setIsUserAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isUserAuthorized = action.payload;
    },
    setSelectedMRA: (state, action: PayloadAction<IComponentDetails>) => {
      state.createAppData.componentDetails.mraId = action.payload.mraId;
      state.createAppData.componentDetails.mraName = action.payload.mraName;
      state.createAppData.componentDetails.componentVariations = action.payload.componentVariations;
      state.createAppData.componentDetails.mraVariations = action.payload.mraVariations;
    },
    setIsCustomizeMRAEnabled: (state, action: PayloadAction<boolean>) => {
      state.isCustomizeMRAEnabled = action.payload;
    },
    setComponentVariations: (state, action: PayloadAction<IComponentVariations>) => {
      state.createAppData.componentDetails.componentVariations.push(action.payload);
    },
    resetComponentVariations: (state) => {
      state.createAppData.componentDetails.componentVariations = [];
    },
    editComponentVariations: (state, action: PayloadAction<IComponentVariations>) => {
      state.createAppData.componentDetails.componentVariations.map((mra) => {
        mra.category === action.payload.category
          ? (mra.choiceIds = action.payload?.choiceIds)
          : mra.choiceIds;
      });
    },
    deleteComponentVariations: (state, action) => {
      state.createAppData.componentDetails.componentVariations =
        state.createAppData.componentDetails.componentVariations.filter(
          (mra) => mra.category !== action.payload
        );
    },
    resetSelectedMRA: (state) => {
      state.createAppData.componentDetails.mraId = '';
      state.createAppData.componentDetails.mraName = '';
      state.createAppData.componentDetails.componentVariations = [];
      state.createAppData.componentDetails.mraVariations = [];
    },
    setAppDetialsValidity: (state, action: PayloadAction<boolean>) => {
      state.createAppData.appDetailsValid = action.payload;
    },
    saveAppData: (state, action: PayloadAction<boolean>) => {
      state.createAppData.loading = action.payload;
    },
    setVersionControl: (state, action: PayloadAction<string>) => {
      state.createAppData.versionControl.name = action.payload;
    },
    resetToken: (state, action: PayloadAction<string>) => {
      state.createAppData.versionControl.token = action.payload;
    },
    resetCreatedAppData: (state) => {
      state.createAppData = {
        applicationDetails: {},
        projectDetails: {},
        componentDetails: { mraId: '', mraName: '', componentVariations: [], mraVariations: [] },
        versionControl: { token: '', name: '' },
        loading: false,
        appDetailsValid: false,
      };
    },
    resetisVariationSupported: (state) => {
      state.isCustomizeMRAEnabled = false;
    },
    updateComponentsStatusByApp: (state, action: PayloadAction<IComponentStatusByAppId>) => {
      const compStatus = action.payload;
      state.components = state.components.map((comp) => {
        const updatedStatus = compStatus[comp._id];
        return {
          ...comp,
          status: updatedStatus,
        };
      });
    },
    updateCompStatusinAppSlice: (state, action: PayloadAction<IComponentStatus>) => {
      const compStatus = action.payload;
      const component = state.components.find((c) => c._id === compStatus.componentId);
      if (component) {
        component.status = compStatus.status;
      }
    },
    changeIsCompStatusCheckTimerRunning: (state, action: PayloadAction<boolean>) => {
      state.isCompStatusCheckTimerRunning = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplication.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.application = action.payload;
      })
      .addCase(fetchApplication.rejected, (state) => {
        state.application = {} as IApplication;
      })
      .addCase(fetchApplicationComponents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchApplicationComponents.fulfilled, (state, action) => {
        state.components = action.payload;
        state.loading = false;
      })
      .addCase(pushAppCode.fulfilled, (state, action) => {
        state.instanceId = action.payload!.id;
      })
      // .addCase(getPRstatus.fulfilled, (state, action) => {
      //   state.prStatus = action.payload!;
      // })
      // .addCase(checkPRStatus.fulfilled, (state, action) => {
      //   state.updatedPrStatus = action.payload!;
      // })
      .addCase(pushAppCodeStatus.fulfilled, (state, action) => {
        state.updatedPrStatus = action.payload!;
      })
      .addCase(fetchChanges.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchChanges.fulfilled, (state, action) => {
        state.changes = action.payload;
        state.loading = false;
      })
      .addCase(checkIfUserIsAuthorized.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkIfUserIsAuthorized.fulfilled, (state, action) => {
        state.loading = false;
        state.isUserAuthorized = action.payload;
      })
      .addCase(getAppCommitLogs.pending, (state) => {
        state.appCommitLogs = [];
        state.logsLoading = true;
      })
      .addCase(getAppCommitLogs.fulfilled, (state, action) => {
        state.appCommitLogs = action.payload;
        state.logsLoading = false;
      })
      .addCase(getApplicationComponents.fulfilled, (state, action) => {
        state.components = action.payload;
      })
      .addCase(getComponentPipelineConfigByAppId.fulfilled, (state, action) => {
        state.componentPipelineConfig = action.payload;
      })
      .addCase(getApplicationPipelineConfig.fulfilled, (state, action) => {
        state.applicationPipelineConfig = action.payload;
      })
      .addCase(getApplicationUsers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApplicationUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.appUsers = action.payload;
      })
      .addCase(generateAccessToken.fulfilled, (state, action) => {
        state.loading = false;
        state.createAppData.versionControl.token = action.payload;
      });
  },
});

export const {
  setApplication,
  clearApplication,
  deleteComponent,
  clearComponents,
  setIsUserAuthorized,
  setSelectedMRA,
  resetSelectedMRA,
  setAppDetialsValidity,
  saveAppData,
  setVersionControl,
  resetToken,
  resetCreatedAppData,
  setPullReqResponse,
  setIsCustomizeMRAEnabled,
  setComponentVariations,
  editComponentVariations,
  deleteComponentVariations,
  resetComponentVariations,
  resetisVariationSupported,
  updateComponentsStatusByApp,
  updateCompStatusinAppSlice,
  changeIsCompStatusCheckTimerRunning,
} = applicationSlice.actions;

export default applicationSlice.reducer;
