import { createAsyncThunk } from '@reduxjs/toolkit';
import applicationPipelineConfigService from 'app/services/pipeline/application-pipeline-config.service';
import componentPipelineConfigService from 'app/services/pipeline/component-pipeline-config.service';

export const getAzureWebAppList = createAsyncThunk(
  'pipelines/getAzureWebAppList',
  async (data: ActionPayload<{ appId: string }>) => {
    try {
      const result = await applicationPipelineConfigService.getWebAppsList(
        data.instance,
        data.appId
      );
      return result.data.map((webapp) => webapp.webAppName);
    } catch (e) {
      return [];
    }
  }
);

export const getApplicationPipelineConfig = createAsyncThunk(
  'pipelines/getApplicationPipelineConfig',
  async (data: ActionPayload<{ appId: string }>) => {
    try {
      const response = await applicationPipelineConfigService.getPipelineConfig(
        data.instance,
        data.appId
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const getComponentPipelineConfig = createAsyncThunk(
  'pipelines/getComponentPipelineConfig',
  async (data: ActionPayload<{ componentId: string; appId: string }>) => {
    try {
      const response = await componentPipelineConfigService.getComponentPipelineConfig(
        data.instance,
        data.componentId,
        data.appId
      );
      return response.data;
    } catch (e) {
      return null;
    }
  }
);
