import { createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorMessage, showInfoMessage } from 'app/layouts/store/slices/message.slice';
import MRAManagementService from 'app/services/mra-management/mra.service';
import StackOfTechService from 'app/services/mra-management/stack-of-tech.service';
import { UploadMRAFormSchema, Trait } from '../../types';

export const getMRA = createAsyncThunk(
  'mrasSlice/getMRA',
  async ({ instance, id }: WithInstance<{ id: string }>, thunkAPI) => {
    try {
      const { data } = await MRAManagementService.getMRA(instance, id);
      // extracting the traits details to a different form
      let noOfLines: string | number = '';
      let storyPoints: number | string = 0;
      const traits: Trait[] = [];
      Object.values(data.traits).forEach(({ key, value }) => {
        if (key === 'linesOfCode') noOfLines = value;
        else if (key === 'storyPoints') storyPoints = value;
        else traits.push({ key, value });
      });
      const defaultValues: UploadMRAFormSchema = {
        name: data.name,
        description: data.description,
        technology: data.technology._id,
        stackOfTechnologies: data.stackOfTechnologies,
        subgenerators: data.subgenerators,
        type: data.type ?? '',
        tags: data.tags.map((tag) => ({ value: tag })),
        logo: {} as FileList,
        // complexEntitySupported: data.complexEntitySupported ?? false,
        folderStructure: {} as FileList,
        mraTemplate: {} as FileList,
        variations: data.variations,
        traits,
        noOfLines,
        storyPoints,
        supportedFeatures: data.supportedFeatures ?? {},
      };
      return defaultValues;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot load MRA details. Try again!'));
      return {
        name: '',
        description: '',
        technology: '',
        type: '',
        subgenerators: [],
        stackOfTechnologies: [],
        tags: [],
        traits: [],
        logo: {} as FileList,
        folderStructure: {} as FileList,
        mraTemplate: {} as FileList,
        // complexEntitySupported: false,
        noOfLines: '',
        storyPoints: 0,
        variations: [],
      };
    }
  }
);

export const getAllPrivateMRAs = createAsyncThunk(
  'mrasSlice/getAllPrivateMRAs',
  async (data: WithInstance<{ tenantId: string }>) => {
    const response = await MRAManagementService.getAllPrivateMRAs(data.instance, data.tenantId);
    return response.data;
  }
);

export const fetchMRAs = createAsyncThunk(
  'mrasSlice/fetchMRAs',
  async (
    data: WithInstance<{ page: number; tenantId: string; fromBeginning?: string }>,
    thunkAPI
  ) => {
    let noMoreDocs = false;
    const queryParams = new URLSearchParams({
      page: `${data.page}`,
      tenantId: `${data.tenantId}`,
      fromBeginning: data.fromBeginning ?? 'false',
    });
    console.log(queryParams);
    const response = await MRAManagementService.fetchPaginatedPrivateMRAs(
      data.instance,
      queryParams
    );
    if (response.data.length === 0) {
      thunkAPI.dispatch(showInfoMessage('No MRAs available'));
    }
    noMoreDocs = response.data.length < 6;
    return {
      data: response.data,
      noMoreDocs,
      fromBeginning: data.fromBeginning ?? 'true',
    };
  }
);

export const checkNameAvailability = createAsyncThunk(
  'mrasSlice/checkNameAvailability',
  async (data: WithInstance<{ tenantId: string; name: string }>) => {
    const response = await MRAManagementService.checkNameAvailability(
      data.instance,
      data.tenantId,
      data.name
    );
    return response.data;
  }
);

export const getAllTechnologies = createAsyncThunk(
  'mrsSlice/getAllTechnologies',
  async (data: WithInstance) => {
    const response = await StackOfTechService.getAllTechnologies(data.instance);
    return response.data ?? [];
  }
);
