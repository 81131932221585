import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import ConfigLoaderService from './app/services/config-loader.service';
import { MsalConfigService } from './app/services/msal-config.service';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
}

ConfigLoaderService.loadConfig().then((response) => {
  const b2cConfig = new MsalConfigService(response);
  const msalInstance = new PublicClientApplication(b2cConfig.msalConfig);

  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  );
});

reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
