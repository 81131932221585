import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchRepositories,
  fetchRepositoryBranches,
  fetchOrganizations,
  getApplicationPipelineConfig,
} from '../actions/settings.actions';
import { Repository } from '../../types';
import { IApplicationPipelineConfig, ContainerRegistry } from 'app/models';

type InitialSettingsState = {
  loading: boolean;
  repositories: Repository[];
  branches: string[];
  organizations: string[];
  isOwner: boolean;
  applicationPipelineConfig: IApplicationPipelineConfig;
};

// const initialApplicationPipelineConfig: IApplicationPipelineConfig = {
//   _id: '',
//   appId: '',
//   createddate: '',
//   createdby: '',
//   pipeline: '',
//   containerRegistry: '',
//   deployment: '',
//   environment: [],
// }

const initialState: InitialSettingsState = {
  loading: false,
  repositories: [],
  branches: [],
  organizations: [],
  isOwner: false,
  applicationPipelineConfig: {} as IApplicationPipelineConfig,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setContainerRegistryDetails: (state, action: PayloadAction<ContainerRegistry>) => {
      state.applicationPipelineConfig = {} as IApplicationPipelineConfig;
      state.applicationPipelineConfig.containerRegistry = action.payload;
    },
    checkIfUserIsOwner: (state, action: PayloadAction<boolean>) => {
      state.isOwner = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepositories.fulfilled, (state, action) => {
        state.repositories = action.payload;
      })
      .addCase(fetchRepositoryBranches.fulfilled, (state, action) => {
        state.branches = action.payload;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload;
      })
      .addCase(getApplicationPipelineConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicationPipelineConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.applicationPipelineConfig = action.payload;
      });
  },
});

export const { checkIfUserIsOwner, setContainerRegistryDetails } = settingsSlice.actions;

export default settingsSlice.reducer;
