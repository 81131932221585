import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchBranches,
  fetchOrgs,
  fetchProjects,
  fetchRepos,
  generatePatToken,
} from '../actions/azure-devops.actions';

export type AzOrganizations = {
  accountId: string;
  accountUri: string;
  accountName: string;
  properties: any;
};

export type AzProjects = {
  id: string;
  name: string;
  description?: string;
  url: string;
  state: string;
  revision: number;
  visibility: number;
  lastUpdateTime: string;
};

export type AzRepos = {
  id: string;
  name: string;
  url: string;
  project: any;
  defaultBranch: string;
  size: number;
  remoteUrl: string;
  sshUrl: string;
  webUrl: string;
  isDisabled: boolean;
  isInMaintenance: boolean;
};

export type AzBranches = {
  commit: Commit;
  name: string;
  aheadCount: number;
  behindCount: number;
  isBaseVersion: boolean;
};

export type Commit = {
  treeId: string;
  commitId: string;
  author: any;
  committer: any;
  comment: string;
  parents: any[];
  url: string;
};

type InitialAzureDevopsState = {
  loading: boolean;
  repositories: AzRepos[];
  branches: AzBranches[];
  organizations: AzOrganizations[];
  projects: AzProjects[];
  isRepoAuth: boolean;
};

const initialState: InitialAzureDevopsState = {
  loading: false,
  repositories: [],
  branches: [],
  organizations: [],
  projects: [],
  isRepoAuth: false,
};

const azureDevopsSlice = createSlice({
  name: 'azureDevopsSlice',
  initialState,
  reducers: {
    setAzRepoAuth: (state, action) => {
      state.isRepoAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrgs.fulfilled, (state, action) => {
      state.organizations = action.payload;
    });
    builder.addCase(generatePatToken.fulfilled, (state, action) => {
      localStorage.setItem('azureDevopsPat', action.payload?.patToken?.token);
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
    });
    builder.addCase(fetchRepos.fulfilled, (state, action) => {
      state.repositories = action.payload;
    });
    builder.addCase(fetchBranches.fulfilled, (state, action) => {
      state.branches = action.payload;
    });
  },
});

export const { setAzRepoAuth } = azureDevopsSlice.actions;

export default azureDevopsSlice.reducer;
