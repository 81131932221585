export class NumberDataField {
  numberId: string;
  numberDataType: string;
  dataFieldName: string;
  numberDescription: string;
  MinimumValue: string;
  MaximumValue: string;
  ExclusiveMinimum: string;
  ExclusiveMaximum: string;
  MultipleOf: string;

  constructor(
    numberId: string,
    numberDataType: string,
    dataFieldName: string,
    numberDescription: string,
    MinimumValue: string,
    MaximumValue: string,
    ExclusiveMinimum: string,
    ExclusiveMaximum: string,
    MultipleOf: string
  ) {
    this.numberId = numberId;
    this.numberDataType = numberDataType;
    this.dataFieldName = dataFieldName;
    this.numberDescription = numberDescription;
    this.MinimumValue = MinimumValue;
    this.MaximumValue = MaximumValue;
    this.ExclusiveMaximum = ExclusiveMaximum;
    this.ExclusiveMinimum = ExclusiveMinimum;
    this.MultipleOf = MultipleOf;
  }
}
