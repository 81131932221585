import { createSlice } from '@reduxjs/toolkit';
import { uploadOASFile } from '../openapi.actions';

type InitialState = {
  inProcessDialog: any;
  loading: boolean;
};

const initialState: InitialState = {
  inProcessDialog: {},
  loading: false,
};

const openApiData = createSlice({
  name: 'openApiStore',
  initialState,
  reducers: {
    openProcessDialog: (state, action) => {
      state.inProcessDialog = { props: { open: true, text: action.payload } };
    },
    closeProcessDialog: (state, action) => {
      state.inProcessDialog = { props: { open: false } };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadOASFile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(uploadOASFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadOASFile.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default openApiData.reducer;
