import { IPublicClientApplication } from '@azure/msal-browser';
import { AddProjInterface, IProject, IUserProjs, Services } from 'app/models';
import { AxiosRequestConfig } from 'axios';
import { RequestHandler } from 'libs/request-handler';

const apiServiceName: Services = 'management';

class ProjectService extends RequestHandler {
  endpath = 'project';

  getAll(instance: IPublicClientApplication) {
    return this.requestHandler(instance).get<IProject[]>(`/${this.endpath}/`);
  }

  findOne(projectId: string, instance: IPublicClientApplication) {
    const headers = { projectid: projectId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get(`/${this.endpath}/${projectId} `, config);
  }
  create(instance: IPublicClientApplication, projectData: IProject) {
    const headers = { projectid: projectData._id };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post(`/${this.endpath}`, projectData, config);
  }

  update(instance: IPublicClientApplication, projectId: any, project: AddProjInterface) {
    const headers = { projectid: projectId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(`/${this.endpath}/${projectId}`, project, config);
  }

  getProjIdAndRolesByUserId(instance: IPublicClientApplication, userId: string) {
    return this.requestHandler(instance).get<IUserProjs>(
      `/${this.endpath}/listPermissions/byUser/${userId}`
    );
  }

  delete(instance: IPublicClientApplication, projectId: string) {
    const headers = { projectid: projectId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).delete(`/${this.endpath}/${projectId}`, config);
  }

  findUsers(projectId: string, instance: IPublicClientApplication) {
    const headers = { projectid: projectId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get(`/${this.endpath}/users/${projectId}`, config);
  }
  deleteUserfromProj(projectId: string, userObj: any, instance: IPublicClientApplication) {
    const headers = { projectid: projectId };
    return this.requestHandler(instance).delete(
      `/${this.endpath}/deleteUserFromProj/project/${projectId}/user`,
      {
        headers: headers,
        data: userObj,
      }
    );
  }
  assignUsers(projectId: any, userids: any, instance: IPublicClientApplication) {
    const headers = { projectid: projectId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).patch(
      `/${this.endpath}/assignUsers/${projectId}`,
      userids,
      config
    );
  }
  fetchProjectsByTenant(tenantId: string, instance: IPublicClientApplication) {
    return this.requestHandler(instance).get(`/${this.endpath}/byTenant/${tenantId} `);
  }

  editProjUser(projectId: any, userid: any, instance: IPublicClientApplication) {
    return this.requestHandler(instance).delete(
      `/${this.endpath}/users/edit/${projectId}/${userid}`
    );
  }
}

export default new ProjectService(apiServiceName);
