import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMRA, Technology } from 'app/models';
import { UploadMRAFormSchema } from '../../types';
import { fetchMRAs, getAllTechnologies, getMRA } from '../actions/mras.actions';

const initialMRA: UploadMRAFormSchema = {
  name: '',
  description: '',
  technology: '',
  type: '',
  subgenerators: [],
  stackOfTechnologies: [],
  tags: [],
  traits: [],
  logo: {} as FileList,
  folderStructure: {} as FileList,
  mraTemplate: {} as FileList,
  // complexEntitySupported: false,
  noOfLines: '',
  storyPoints: 0,
  variations: [],
  supportedFeatures: {
    crud: false,
    businessValidations: false,
    complexEntities: false,
    apiBuilder: false,
  },
};

type InitialMraState = {
  mras: IMRA[];
  noMoreMras: boolean;
  technologies: Technology[];
  defaultMRA: UploadMRAFormSchema;
  mode: 'add' | 'edit';
  activeStep: number;
  loading: boolean;
};

const initialState: InitialMraState = {
  mras: [],
  noMoreMras: false,
  technologies: [],
  mode: 'add',
  defaultMRA: initialMRA,
  activeStep: 0,
  loading: false,
};

const mrasSlice = createSlice({
  name: 'mrasSlice',
  initialState,
  reducers: {
    deleteMRA: (state, action: PayloadAction<string>) => {
      state.mras = state.mras.filter((mra) => mra._id !== action.payload);
    },
    clearMRAs: (state) => {
      state.mras = [];
      state.noMoreMras = false;
    },
    setEditMRA: (state, action) => {
      state.defaultMRA = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    clearCurrentMRA: (state) => {
      state.defaultMRA = initialMRA;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMRAs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMRA.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMRA.fulfilled, (state, action) => {
        state.loading = false;
        state.defaultMRA = action.payload;
      })
      .addCase(fetchMRAs.fulfilled, (state, action) => {
        state.mras = state.mras.concat(action.payload.data);
        state.noMoreMras = action.payload.noMoreDocs;
        state.loading = false;
      })
      .addCase(getAllTechnologies.fulfilled, (state, action) => {
        state.technologies = action.payload;
      });
  },
});

export const { deleteMRA, clearMRAs, setEditMRA, setMode, clearCurrentMRA } = mrasSlice.actions;

export default mrasSlice.reducer;
