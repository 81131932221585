import { IPublicClientApplication } from '@azure/msal-browser';
import { RequestHandler } from 'libs/request-handler';
import { AxiosRequestConfig } from 'axios';
import { ICreatePrStatusResponse, IGetCreatePrResponse, Services } from 'app/models';

const apiServiceName: Services = 'app-designer';

class AzureDevopsService extends RequestHandler {
  endpath = 'azure-devops';

  createAzPullRequest(
    instance: IPublicClientApplication,
    applicationId: string,
    commitMessage: string
  ) {
    const data = {
      message: commitMessage,
      appId: applicationId,
    };
    const headers = { applicationid: applicationId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).post<IGetCreatePrResponse>(
      `/${this.endpath}/create/az/pullrequest`,
      data,
      config
    );
  }

  getAzPRstatus(instance: IPublicClientApplication, instanceId: string) {
    const data = {
      instanceId: instanceId,
    };
    const headers = { instanceid: instanceId };
    const config: AxiosRequestConfig = { headers };
    return this.requestHandler(instance).get<ICreatePrStatusResponse>(
      `/${this.endpath}/pr/status/${data.instanceId}`,
      config
    );
  }

  getAccessToken(instance: IPublicClientApplication, code: string, orgName: string) {
    const headers = { token: code };
    return this.requestHandler(instance).post(
      `${this.endpath}/authorize`,
      { orgName: orgName },
      { headers: headers }
    );
  }

  getOrganizations(instance: IPublicClientApplication, code: string) {
    return this.requestHandler(instance).post(`${this.endpath}/listOrgs`, { code: code });
  }

  getProjects(instance: IPublicClientApplication, token: string, orgName: string) {
    return this.requestHandler(instance).post(`${this.endpath}/listProjects`, {
      token: token,
      orgName: orgName,
    });
  }

  getRepos(
    instance: IPublicClientApplication,
    token: string,
    orgName: string,
    projectName: string
  ) {
    return this.requestHandler(instance).post(`${this.endpath}/listRepos`, {
      token: token,
      orgName: orgName,
      projectName: projectName,
    });
  }

  getBranches(
    instance: IPublicClientApplication,
    token: string,
    orgName: string,
    repoId: string,
    projectName: string
  ) {
    return this.requestHandler(instance).post(`${this.endpath}/listBranches`, {
      token: token,
      orgName: orgName,
      repoId: repoId,
      projectName: projectName,
    });
  }
}

export default new AzureDevopsService(apiServiceName);
