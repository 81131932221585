import { IPublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk } from '@reduxjs/toolkit';
import codespaceService from 'app/services/app-designer/codespace.service';
import componentService from 'app/services/app-designer/component.service';
import MRAManagementService from 'app/services/mra-management/mra.service';
import ComponentPipelineConfigService from 'app/services/pipeline/component-pipeline-config.service';
import { ReleasePipeline } from 'app/main/application/types';
import entityService from 'app/services/app-designer/entity.service';
import { showErrorMessage, showSuccessMessage } from 'app/layouts/store/slices/message.slice';

export const fetchComponent = createAsyncThunk(
  'componentStore/fetchComponent',
  async (data: ActionPayload<{ componentId: string; appId: string }>) => {
    const compResponse = await componentService.getComponent(
      data.instance,
      data.componentId,
      data.appId
    );
    const mraResponse = await MRAManagementService.getMRA(data.instance, compResponse.data.mra.id);
    return {
      component: compResponse.data,
      mra: mraResponse.data,
    };
  }
);

export const getApplicationCodespaceUrl = createAsyncThunk(
  'appsStore/getApplicationCodespaceUrl',
  async (
    data: ActionPayload<{ instance: IPublicClientApplication; applicationId: string }>,
    thunkAPI
  ) => {
    try {
      const response = await codespaceService.getCodeSpaceUrl(data.instance, data.applicationId);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot fetch codespace url.'));
    }
  }
);
export const fetchComponentEntities = createAsyncThunk(
  'componentSlice/fetchComponentEntities',
  async (data: ActionPayload<{ componentId: string }>) => {
    try {
      const response = await entityService.getComponentEntities(data.instance, data.componentId);
      return response.data;
    } catch (err) {
      return [];
    }
  }
);

export const getStatusOfCodespace = createAsyncThunk(
  'appsStore/getCodespaceStatus',
  async (
    data: ActionPayload<{
      instance: IPublicClientApplication;
      instanceId: string;
      applicationId: string;
    }>,
    thunkAPI
  ) => {
    try {
      const response = await codespaceService.getStatus(
        data.instance,
        data.instanceId,
        data.applicationId
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(showErrorMessage('Cannot fetch codespace url.'));
    }
  }
);

export const generateCrud = createAsyncThunk(
  'componentSlice/generateCrud',
  async (
    data: ActionPayload<{
      data: { componentId: string; id: string };
      applicationId: string;
      name: string;
    }>,
    thunkAPI
  ) => {
    try {
      const response = await componentService.generateCrud(
        data.instance,
        data.data,
        data.applicationId
      );
      if (response.status >= 200 && response.status <= 300) {
        const entities = response.data;
        const generatedEntities = entities
          .map((entity: { name: string }) => entity.name)
          .join(', ');
        console.log(generatedEntities);
        thunkAPI.dispatch(
          showSuccessMessage(`Generated CRUD for ${generatedEntities} scheduled successfully`)
        );
        return response.data;
      } else {
        thunkAPI.dispatch(
          showErrorMessage(`Scheduling for CRUD Generation for ${data.name} failed`)
        );
      }
    } catch (err) {
      thunkAPI.dispatch(showErrorMessage(`Scheduling for CRUD Generation for ${data.name} failed`));
    }
  }
);

export const getComponentPipelineConfig = createAsyncThunk(
  'componentStore/getBuildPipelines',
  async (data: ActionPayload<{ componentId: string; applicationId: string }>) => {
    try {
      const response = await ComponentPipelineConfigService.getComponentPipelineConfig(
        data.instance,
        data.componentId,
        data.applicationId
      );
      return response.data;
    } catch (e) {}
  }
);

export const addReleasePipeline = createAsyncThunk(
  'componentStore/addReleasePipeline',
  async (
    data: ActionPayload<{ componentId: string; appId: string; releasePipeline: ReleasePipeline }>
  ) => {
    try {
      const response = await ComponentPipelineConfigService.addReleasePipeline(
        data.instance,
        data.componentId,
        data.releasePipeline,
        data.appId
      );
      return response.data;
    } catch (e) {}
  }
);

export const deleteReleasePipeline = createAsyncThunk(
  'componentStore/deleteReleasePipeline',
  async (
    data: ActionPayload<{
      applicationId: string;
      componentId: string;
      releasePipeline: ReleasePipeline;
    }>
  ) => {
    try {
      const response = await ComponentPipelineConfigService.deleteReleasePipeline(
        data.instance,
        data.applicationId,
        data.componentId,
        data.releasePipeline
      );
      return response.data;
      // dispatch(showMessage(messageOptions("Release Pipeline deleted successfully", "success")));
      // dispatch(closeProcessDialog());
    } catch (e) {
      // dispatch(closeProcessDialog());
      // dispatch(showMessage(messageOptions("Release Pipeline not deleted", "error")))
    }
  }
);

export const generateBusinessValidation = createAsyncThunk(
  'componentSlice/generateBusinessValidation',
  async (
    data: ActionPayload<{
      data: { componentId: string; id: string };
      applicationId: string;
      name: string;
    }>,
    thunkAPI
  ) => {
    try {
      const response = await componentService.generateBusinessValidation(
        data.instance,
        data.data,
        data.applicationId
      );
      if (response.status >= 200 && response.status <= 300) {
        console.log(response.data);
        thunkAPI.dispatch(
          showSuccessMessage(`Business Validation Generation for ${name} Scheduled Successfully`)
        );
      } else if (response.status === 500) {
        thunkAPI.dispatch(
          showSuccessMessage(
            `Add validation options for the entity to generate business validations`
          )
        );
      } else {
        thunkAPI.dispatch(
          showErrorMessage(`Business Validation Generation for ${name} is not scheduled`)
        );
      }
      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(showErrorMessage(`${err.message}`));
      return undefined;
    }
  }
);

export const getEntitiesData = createAsyncThunk(
  'componentSlice/getEntitiesData',
  async (data: ActionPayload<{ entityIds: string[]; applicationId: string }>, thunkAPI) => {
    try {
      const response = await entityService.getEntitiesById(
        data.instance,
        data.entityIds,
        data.applicationId
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(showErrorMessage('Cannot load entities'));
      return [];
    }
  }
);
