import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../../assets/401.json';
import { Button, Typography } from '@mui/material';

function NotAuthenticated() {
  return (
    <main className="flex flex-col lg:flex-row justify-center items-center w-[100vw] h-[100vh] gap-10">
      <div className="w-1/2">
        <Lottie animationData={animation} loop={true} />
      </div>
      <section className="flex flex-col gap-2 justify-center items-center p-10 text-center">
        <Typography className="font-bold text-6xl">401 Hold Up!</Typography>
        <Typography variant="h4" fontWeight={600}>
          You are not authenticated to view this page
        </Typography>
        <Typography color="text.secondary" variant="h6">
          Sorry about that! Please login to access RapidX!
        </Typography>
        <div>
          <Button variant="contained" component={Link} to="/">
            Go to home
          </Button>
        </div>
      </section>
    </main>
  );
}

export default NotAuthenticated;
