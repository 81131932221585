import { PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from './store';

const RapidXAuthentication = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();

  const userContext = useSelector((store) => store.authSlice.user);

  useEffect(() => {
    const authenticated = checkUserAuthentication();
    if (authenticated) {
      if (location.pathname === '/login') navigate('/');
    } else {
      if (location.pathname !== '/login' && location.pathname !== '/register') navigate('/login');
    }
  }, [location]);

  function checkUserAuthentication(): boolean {
    return !!userContext._id;
  }

  return <>{children}</>;
};

export default RapidXAuthentication;
